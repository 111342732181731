import { toast } from 'react-toastify'
import requestApi from 'utility/requestApi';
import queryString from 'query-string';
import {
  GET_PERMISSIONS_START,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
} from 'redux/actionTypes';

export const getPermissions = () => async dispatch => {
  dispatch({ type: GET_PERMISSIONS_START });
  try {
    let { data } = await requestApi.get(`/api/v1/Permission?per_page=100`);
    data = data.map(permission => ({ ...permission, key: `${permission.id}_${Math.random() * 1000}` }))
    dispatch({ type: GET_PERMISSIONS_SUCCESS, permissions: data });
  } catch (err) {
    dispatch({ type: GET_PERMISSIONS_FAILURE, err });
    return Promise.reject(err.response?.data)
  }
}

export const getRoles = (query) => async dispatch => {
  dispatch({ type: GET_ROLES_START });
  try {
    const { data } = await requestApi.get(`/api/v1/Role?${queryString.stringify({ per_page: 100, ...query })}`);
    dispatch({ type: GET_ROLES_SUCCESS, roles: data });
  } catch (err) {
    if (err.response?.status === 404) {
      dispatch({ type: GET_ROLES_SUCCESS, payload: {} });
    } else {
      dispatch({ type: GET_ROLES_FAILURE, err });
      return Promise.reject(err.response.data)
    }
  }
}

export const createRole = ({ userId, ...payload }) => async dispatch => {
  dispatch({ type: CREATE_ROLE_START, payload });
  try {
    const { data } = await requestApi.post(`/api/v1/Role/${userId}`, payload);
    dispatch({ type: CREATE_ROLE_SUCCESS, role: data });
  } catch (err) {
    if (err.response?.status === 404) {
      dispatch({ type: CREATE_ROLE_SUCCESS, payload: {} });
    } else {
      dispatch({ type: CREATE_ROLE_FAILURE, err });
      return Promise.reject(err.response.data)
    }
  }
}

export const updateRole = ({ roleId, ...payload }) => async dispatch => {
  dispatch({ type: UPDATE_ROLE_START, roleId, ...payload });
  try {
    const { data } = await requestApi.put(`/api/v1/Role/${roleId}`, payload);
    dispatch({ type: UPDATE_ROLE_SUCCESS, role: data });
  } catch (err) {
    if (err.response?.status === 404) {
      dispatch({ type: UPDATE_ROLE_SUCCESS, payload: {} });
    } else {
      dispatch({ type: UPDATE_ROLE_FAILURE, err });
      return Promise.reject(err.response.data)
    }
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import { Button as ReactStrapButton } from 'reactstrap';
import Ripples from 'react-ripples'

const Button = ({ rippleColor='#ffffff35', during=1000, color="primary", ...rest }) => (
  <Ripples color={rippleColor} during={during}>
    <ReactStrapButton color={color} {...rest} />
  </Ripples>
);

Button.propTypes = {
  ...ReactStrapButton.propTypes,
  rippleColor: PropTypes.string,
  during: PropTypes.number,
};

export default Button;

import {
  GET_TAGS_START,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
} from 'redux/actionTypes';

const initialState = {
  all: [],
  loading: true,
  err: null,
}

const tagsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS_START:
      return { ...state, loading: true }
    case GET_TAGS_SUCCESS:
      return { ...state, loading: false, all: action.payload, err: null }
    case GET_TAGS_FAILURE:
      return { ...state, loading: false, err: action.err }
    default:
      return state
  }
}

export default tagsReducers;

const getValue = (row, sortColumn) => {
  if (typeof sortColumn.selector === 'string') {
    return row[sortColumn.selector]
  } else {
    return sortColumn.selector(row);
  }
}

const sortAlphaNum = (a, b, sortColumn) => {
  const aValue = '' + getValue(a, sortColumn)
  const bValue = '' + getValue(b, sortColumn)
  if (sortColumn.sortDirection === 'asc') {
    return aValue.localeCompare(bValue, 'en', { numeric: true });
  } else {
    return bValue.localeCompare(aValue, 'en', { numeric: true });
  }
};

const sortTable = (data, sortColumn) => {
  return data.sort((a, b) => sortAlphaNum(a, b, sortColumn))
}

export default sortTable;

import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Row } from 'reactstrap';
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux';
import { getRegionSettings } from 'redux/actions/settings/region';
import capitalize from 'utility/capitalize';
import NumberFormat from 'react-number-format';
import { getTags } from 'redux/actions/tags';
import { getBuildingsTagsByCustomer } from 'redux/actions/buildings';

const formatTags = tags => tags.map(tag => ({ ...tag, label: tag.tag || tag.name, value: tag.id, name: tag.tag || tag.name }));

const SidebarUserForm = ({ values, setValues }) => {
  const { email, name, lastName, status, phone, area, role, userTags, customerTags, buildingTags } = values;
  const regionsTags = useSelector(({ settings }) => settings.region.tags)
  const [regionsLoaded, setRegionsLoaded] = useState(false);
  const [availableUserTags, setAvailableUserTags] = useState([]);
  const [availableBuildingTags, setAvailableBuildingTags] = useState([]);
  const [availableCustomerTags, setAvailableCustomerTags] = useState([]);
  const userId = useSelector(({ auth }) => auth.currentUser.id);
  const buildingTagsByCustomer = useSelector(({ buildings }) => buildings.buildingTagsByCustomer);
  const tagsCategory = useSelector(({ tags }) => tags.all);
  const roles = useSelector(({ permissions }) => permissions.roles);
  const currentUserRole = useSelector(({ auth }) => auth.currentUser.role);
  const dispatch = useDispatch();

  useEffect(() => {
    if (regionsLoaded) return;

    if (regionsTags.length === 0) {
      dispatch(getRegionSettings(userId));
    }

    setRegionsLoaded(true)
  }, [regionsTags, dispatch, regionsLoaded, userId])

  useEffect(() => {
    if (!area && regionsTags.length) {
      setValues({ ...values, area: regionsTags[0].tagDescription })
    }
  })

  useEffect(() => {
    dispatch(getBuildingsTagsByCustomer({ userId, customerTags: customerTags?.map(tag => tag.value) }))
  }, [dispatch, userId, customerTags])

  useEffect(() => {
    dispatch(getTags(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    const buildingCategories = tagsCategory.filter(cate => cate.type === 'building');
    const newBuildingTags = buildingCategories.map(category => category.tags).flat().map(tag => ({ ...tag, tagDescription: tag.name }));

    if (customerTags?.length > 0) {
      setAvailableBuildingTags(formatTags(buildingTagsByCustomer || []))
    } else {
      setAvailableBuildingTags(newBuildingTags)
    }
  }, [buildingTagsByCustomer, tagsCategory, customerTags])

  useEffect(() => {
    const userCategories = tagsCategory.filter(cate => cate.type === 'user');
    const customerCategories = tagsCategory.filter(cate => cate.type === 'customer');
    const newUserTags = userCategories.map(category => category.tags).flat().map(tag => ({ ...tag, tagDescription: tag.name }));
    const newCustomerTags = customerCategories.map(category => category.tags).flat().map(tag => ({ ...tag, tagDescription: tag.name }));
    setAvailableUserTags(newUserTags)
    setAvailableCustomerTags(newCustomerTags)
  }, [tagsCategory]);

  const filteredRoles = roles.filter(role => {
    if (currentUserRole === 'Super User') return true;
    return role.name !== 'Super User';
  });

  return (
    <Row>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="firstName">First Name  <sup
          className="required"
          style={{ color: "red" }}
        >
          * </sup>{" "}
        </Label>
        <Input
          type="text"
          value={name || ''}
          onChange={e => setValues({ ...values, name: e.target.value })}
          id="firstName"
          required
        />
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="lastName">Last Name  <sup
          className="required"
          style={{ color: "red" }}
        >
          * </sup>{" "}
        </Label>
        <Input
          type="text"
          value={lastName || ''}
          onChange={e => setValues({ ...values, lastName: e.target.value })}
          id="lastName"
          required
        />
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="email">Email Address   <sup
          className="required"
          style={{ color: "red" }}
        >
          * </sup>{" "}
        </Label>
        <Input
          type="email"
          value={email || ''}
          onChange={e => setValues({ ...values, email: e.target.value })}
          id="email"
          required
        />
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="email">Mobile Phone Number   <sup
          className="required"
          style={{ color: "red" }}
        >
          * </sup>{" "}
        </Label>
        <br />
        <NumberFormat
          style={{ border: "1px solid #d9d9d9", height: "34px", borderRadius: "5px", width: "100%" }}
          type="text"
          value={phone || ''}
          onChange={e => setValues({ ...values, phone: e.target.value })}
          id="phone"
          format="(###) ###-####"
          className="form-control"
          required
        />
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="area">Area/Region</Label>
        <Input
          className="form-control"
          type="select"
          name="area"
          id="area"
          value={area || ''}
          onChange={e => setValues({ ...values, area: e.target.value })}
        >
          {
            regionsTags.map(({ tagDescription }) => (
              <option key={tagDescription} value={tagDescription}>{capitalize(tagDescription)}</option>
            ))
          }
        </Input>
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="status">Status</Label>
        <Input
          type="select"
          name="status"
          id="status"
          value={status}
          onChange={e => setValues({ ...values, status: e.target.value })}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="pending">Pending</option>
        </Input>
      </FormGroup>
      {
        currentUserRole !== 'Super User' && role === 'Super User' ? null :
          <FormGroup className="col-sm-12 data-field-col">
            <Label for="email">Role</Label>
            <Select
              type="select"
              name="role"
              id="role"
              value={{ label: role, value: role }}
              onChange={option => setValues({ ...values, role: option.value })}
              options={filteredRoles.map(role => ({ label: role.name, value: role.name }))}
            >
            </Select>
          </FormGroup>
      }
      <FormGroup className="col-sm-12 data-field-col">
        <hr className="w-100" />
        <p className="mb-0">Use Tags to a limit a User’s access to specific Customers and/or Buildings.</p>
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="tags">User Tags</Label>
        <Select
          value={userTags}
          options={availableUserTags.map(tag => ({ label: tag.name, value: tag.id }))}
          onChange={(option) => setValues({ ...values, userTags: option })}
          isMulti={true}
        />
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="tags">Customer Tags</Label>
        <Select
          value={customerTags}
          options={availableCustomerTags.map(tag => ({ label: tag.name, value: tag.id }))}
          onChange={(option) => setValues({ ...values, customerTags: option })}
          isMulti={true}
        />
      </FormGroup>
      <FormGroup className="col-sm-12 data-field-col">
        <Label for="tags">Building Tags</Label>
        <Select
          value={buildingTags}
          options={availableBuildingTags.map(tag => ({ label: tag.name, value: tag.id }))}
          onChange={(option) => setValues({ ...values, buildingTags: option })}
          isMulti={true}
        />
      </FormGroup>
    </Row>
  )
}

export default SidebarUserForm;

import { toast } from 'react-toastify'
import requestApi from 'utility/requestApi';
import {
  GET_TAGS_START,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  ADD_TAG_CATEGORY_SUCCESS,
  ADD_TAG_CATEGORY_FAILURE,
  ADD_TAG_CATEGORY_START,
  ADD_TAG_START,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAILURE,
  REMOVE_TAG_START,
  REMOVE_TAG_SUCCESS,
  REMOVE_TAG_FAILURE,
} from 'redux/actionTypes';


export const getTags = (userId) => async dispatch => {
  dispatch({ type: GET_TAGS_START });
  try {
    const { data } = await requestApi.get(`/api/v1/Tag/${userId}`);
    dispatch({ type: GET_TAGS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_TAGS_FAILURE, err });
    return Promise.reject(err.response.data)
  }
}

export const addTagCategory = (data, userId) => async dispatch => {
  dispatch({ type: ADD_TAG_CATEGORY_START, payload: data });
  try {
    const { data: category } = await requestApi.post(`/api/v1/Tag/${userId}`, data);
    toast.success('Saved!');
    dispatch({ type: ADD_TAG_CATEGORY_SUCCESS, payload: category });
  } catch (err) {
    toast.error('there was an error with this action!');
    dispatch({ type: ADD_TAG_CATEGORY_FAILURE, err });
    return Promise.reject(err.response.data)
  }
}

export const addTag = (data, categoryId) => async dispatch => {
  dispatch({ type: ADD_TAG_START, payload: data });
  try {
    const { data: tag } = await requestApi.post(`/api/v1/Tag/AddTag/${categoryId}`, data);
    toast.success('Saved!');
    dispatch({ type: ADD_TAG_SUCCESS, payload: tag });
    return tag;
  } catch (err) {
    toast.error('there was an error with this action!');
    dispatch({ type: ADD_TAG_FAILURE, err });
    return Promise.reject(err.response.data)
  }
}

export const removeTag = (tagId) => async dispatch => {
  dispatch({ type: REMOVE_TAG_START });
  try {
    const { data: tag } = await requestApi.delete(`/api/v1/Tag/RemoveTag/${tagId}`);
    toast.success('Deleted!');
    dispatch({ type: REMOVE_TAG_SUCCESS });
    return tag;
  } catch (err) {
    toast.error('there was an error with this action!');
    dispatch({ type: REMOVE_TAG_FAILURE, err });
    return Promise.reject(err.response.data)
  }
}

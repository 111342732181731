import { toast } from 'react-toastify'
import {
  LOAD_PARNERTS_REQUEST,
  LOAD_PARNERTS_SUCCESS,
  LOAD_PARNERTS_ERROR,
  CREATE_PARNERTS_REQUEST,
  CREATE_PARNERTS_SUCCESS,
  CREATE_PARNERTS_ERROR,
  LOAD_PARNERT_REQUEST,
  LOAD_PARNERT_SUCCESS,
  LOAD_PARNERT_ERROR,
  DELETE_PARNERTS_REQUEST,
  DELETE_PARNERTS_SUCCESS,
  DELETE_PARNERTS_ERROR,
  EDIT_PARNERTS_REQUEST,
  EDIT_PARNERTS_SUCCESS,
  EDIT_PARNERTS_ERROR,
  CHANGE_PARTNER_STATUS,
  LOAD_ALL_PARNERTS_SUCCESS,
  LOAD_ALL_PARNERT_REQUEST,
  LOAD_ALL_PARNERT_ERROR,
} from 'redux/actions/partners'

const initialState = {
  list: [],
  partners: {},
  creating: false,
  loading: false,
  loadingAll: true,
  all: [],
}

const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PARNERTS_REQUEST:
    case CREATE_PARNERTS_REQUEST:
    case LOAD_PARNERT_REQUEST:
    case DELETE_PARNERTS_REQUEST:
    case EDIT_PARNERTS_REQUEST:
      return { ...state, loading: true }
    case CHANGE_PARTNER_STATUS:
      return { ...state, creating: action.payload.value }
    case LOAD_PARNERTS_SUCCESS:
      return { ...state, list: action.items, page: action.page }
    case CREATE_PARNERTS_SUCCESS:
      toast.success('Partner created correctly!')
      return { ...state, creating: action.payload }
    case LOAD_PARNERT_SUCCESS:
      return { ...state, partners: { ...state.partners, [action.payload.id]: action.payload } }
    case DELETE_PARNERTS_SUCCESS:
      return { ...state }
    case EDIT_PARNERTS_SUCCESS:
      toast.success('Partner edit correctly!')
      return { ...state, creating: action.payload }

    case LOAD_ALL_PARNERTS_SUCCESS:
      return { ...state, loadingAll: false, all: action.items }
    case LOAD_ALL_PARNERT_REQUEST:
      return { ...state, loadingAll: true }
    case LOAD_ALL_PARNERT_ERROR:
      return { ...state, loadingAll: false }

    case CREATE_PARNERTS_ERROR:
    case EDIT_PARNERTS_ERROR:
      toast.error('Something went wrong, please try again!')
      return { ...state, loading: false, creating: false }
    case LOAD_PARNERTS_ERROR:
    case LOAD_PARNERT_ERROR:
    case DELETE_PARNERTS_ERROR:
      return { ...state, loading: false, creating: false }
    default:
      return state
  }
}

export default partnersReducer
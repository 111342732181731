import {
  PAY_SUBSCRIPTION_START,
  PAY_SUBSCRIPTION_SUCCESS,
  PAY_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION_HISTORY_START,
  GET_SUBSCRIPTION_HISTORY_SUCCESS,
  GET_SUBSCRIPTION_HISTORY_FAILURE,
} from 'redux/actionTypes';

const initialState = {
  error: null,
  loading: true,
  loadingPayment: false,
  history: [],
  subscription: {},
}

export default (state = initialState, action) => {
  switch (action.type) {

    case PAY_SUBSCRIPTION_START: {
      return { ...state, loadingPayment: true }
    }
    case PAY_SUBSCRIPTION_SUCCESS: {
      return { ...state, loadingPayment: false, subscription: action.subscription }
    }
    case PAY_SUBSCRIPTION_FAILURE: {
      return { ...state, loadingPayment: false, error: action.err }
    }

    case GET_SUBSCRIPTION_HISTORY_START: {
      return { ...state, loading: true }
    }
    case GET_SUBSCRIPTION_HISTORY_SUCCESS: {
      return { ...state, loading: false, history: action.history }
    }
    case GET_SUBSCRIPTION_HISTORY_FAILURE: {
      return { ...state, loading: false, error: action.err }
    }

    default: {
      return state
    }
  }
}

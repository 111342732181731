import {
    GET_DEVICES_START,
    GET_DEVICES_SUCCESS,
    GET_DEVICES_FAILURE,
    CREATE_DEVICE_START,
    CREATE_DEVICE_SUCCESS,
    CREATE_DEVICE_FAILURE,
    GET_DEVICES_BY_MONTH_START,
    GET_DEVICES_BY_MONTH_SUCCESS,
    GET_DEVICES_BY_MONTH_FAILURE,
    EDIT_DEVICE_SUCCESS,
    LOGOUT_SUCCESS,
  } from "redux/actionTypes"
  
  const initialState = {
    all: [],
    loading: true,
    err: null,
    loadingDevice: false,
    device: {},
    devicesByMonth: []
  }
  
  const devicesReducers = (state = initialState, action) => {
    switch (action.type) {
      case GET_DEVICES_START:
      case GET_DEVICES_BY_MONTH_START:
        return {...state, loading: true}
      case GET_DEVICES_BY_MONTH_SUCCESS: 
        return { ...state, devicesByMonth: action.data }
      case GET_DEVICES_SUCCESS: 
        return {...state, loading: false, all: action.devices, err: null}
      case GET_DEVICES_FAILURE:
        return {...state, loading: false, err: action.err}
      case CREATE_DEVICE_START:
        return {...state, loadingDevice: true}
      case CREATE_DEVICE_SUCCESS: 
        return {...state, loadingDevice: false, all: [...state.all, action.device], err: null}
      case EDIT_DEVICE_SUCCESS: 
        let pos = state.all.findIndex( v => v.id === action.device.id )
        let all = [...state.all]
        all[pos] = action.device
        return {...state, loadingDevice: false, all: [...all], err: null}
      case LOGOUT_SUCCESS: 
        return { ...initialState }
      case CREATE_DEVICE_FAILURE:
      case GET_DEVICES_BY_MONTH_FAILURE:
        return {...state, loading: false}
      default:
        return state
    }
  }
  
  export default devicesReducers
  
import { toast } from 'react-toastify';
import requestApi from 'utility/requestApi';
import queryString from 'query-string';
import {
  GET_BUILDINGS_FAILURE,
  GET_BUILDINGS_START,
  GET_BUILDINGS_SUCCESS,
  CREATE_BUILDING_FAILURE,
  CREATE_BUILDING_START,
  CREATE_BUILDING_SUCCESS,
  GET_BUILDING_START,
  GET_BUILDING_SUCCESS,
  GET_BUILDING_FAILURE,
  UPDATE_BUILDING_START,
  UPDATE_BUILDING_SUCCESS,
  UPDATE_BUILDING_FAILURE,
  SET_CURRENT_BUILDING,
  UPDATE_BUILDING_AREA_START,
  CREATE_BUILDING_AREA_START,
  UPDATE_BUILDING_AREA_SUCCESS,
  UPDATE_BUILDING_AREA_FAILURE,
  CREATE_BUILDING_AREA_SUCCESS,
  CREATE_BUILDING_AREA_FAILURE,
  DELETE_BUILDING_AREA_START,
  DELETE_BUILDING_AREA_SUCCESS,
  DELETE_BUILDING_AREA_FAILURE,
  CREATE_BUILDING_CUSTOM_AREA_START,
  CREATE_BUILDING_CUSTOM_AREA_SUCCESS,
  CREATE_BUILDING_CUSTOM_AREA_FAILURE,
  UPDATE_BUILDING_CUSTOM_AREA_START,
  UPDATE_BUILDING_CUSTOM_AREA_SUCCESS,
  UPDATE_BUILDING_CUSTOM_AREA_FAILURE,
  DELETE_BUILDING_CUSTOM_AREA_START,
  DELETE_BUILDING_CUSTOM_AREA_SUCCESS,
  DELETE_BUILDING_CUSTOM_AREA_FAILURE,
  UPDATE_BUILDING_CUSTOM_AREA_ORDER_START,
  UPDATE_BUILDING_CUSTOM_AREA_ORDER_FAILURE,
  UPDATE_BUILDING_CUSTOM_AREA_ORDER_SUCCESS,
  GET_BUILDINGS_TAGS_BY_CUSTOMER_START,
  GET_BUILDINGS_TAGS_BY_CUSTOMER_SUCCESS,
  GET_BUILDINGS_TAGS_BY_CUSTOMER_FAILURE,
} from 'redux/actionTypes';

export const getBuildingsTagsByCustomer = ({ userId, customerTags }) => async dispatch => {
  dispatch({ type: GET_BUILDINGS_TAGS_BY_CUSTOMER_START });
  try {
    const { data } = await requestApi.get(`/api/v1/Building/GetBuildingTags/${userId}?${queryString.stringify({ customerTags })}`);
    dispatch({ type: GET_BUILDINGS_TAGS_BY_CUSTOMER_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_BUILDINGS_TAGS_BY_CUSTOMER_FAILURE,
      err,
    })
    toast.error('Something wrong happened, please try again!');
  }
}

export const getBuildings = ({ userId, query }) => async dispatch => {
  dispatch({ type: GET_BUILDINGS_START })
  try {
    const { data: { paging, data } } = await requestApi.get(`/api/v1/Building/GetByPartner/${userId}${query || ''}`);
    dispatch({ type: GET_BUILDINGS_SUCCESS, buildings: data, paging });
  } catch (err) {
    dispatch({
      type: GET_BUILDINGS_FAILURE,
      err,
    })
    toast.error('Something wrong happened, please try again!');
  }
}

export const createBuilding = ({ userId, data }) => async dispatch => {
  dispatch({ type: CREATE_BUILDING_START, payload: data });
  try {
    const { data: building } = await requestApi.post(`/api/v1/Building/${userId}`, data);
    dispatch({ type: CREATE_BUILDING_SUCCESS, building });
    toast.success('Building created!');
    return building;
  } catch (err) {
    dispatch({ type: CREATE_BUILDING_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const updateBuilding = ({ buildingId, data }) => async dispatch => {
  dispatch({ type: UPDATE_BUILDING_START, payload: data });
  try {
    const { data: building } = await requestApi.put(`/api/v1/Building/${buildingId}`, data);
    dispatch({ type: UPDATE_BUILDING_SUCCESS, building });
    toast.success('Building saved!');
  } catch (err) {
    dispatch({ type: UPDATE_BUILDING_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const getBuilding = ({ buildingId }) => async (dispatch, getState) => {
  dispatch({ type: GET_BUILDING_START })
  try {
    const { data: building } = await requestApi.get(`/api/v1/Building/${buildingId}`);
    const areas = building.zone.areas.map(area => {
      return area.customAreas.map(customArea => ({...customArea, parentId: area.id}))
    }).flat()
    areas.forEach(area => {
      if (area.assesments.length === 0) {
        const externalArea = getState().audits.externalAreas.find(extArea => extArea.id === area.externalID)
        if (!externalArea) return;

        area.assesments = externalArea.items.map(item => {
          const assesment = { ...item };
          assesment.externalID = assesment.id
          assesment.id = Math.random() * 10000000;
          return assesment;
        })
      }
    });
    if (building.app) {
      const mainArea = {
        externalID: null,
        floor: 0,
        id: building.zone.areas[0]?.id,
        app: true,
        name: building?.zone?.name,
        status: true,
        customAreas: areas,
      }
      building.zone.areas = [mainArea];
    }
    dispatch({ type: GET_BUILDING_SUCCESS, building });
  } catch (err) {
    dispatch({
      type: GET_BUILDING_FAILURE,
      err,
    });
    toast.error(err.message);
  }
}

export const setCurrentBuilding = (building) => {
  return { type: SET_CURRENT_BUILDING, building }
}

export const updateBuildingArea = ({ area }) => async dispatch => {
  dispatch({ type: UPDATE_BUILDING_AREA_START, payload: area });
  const payload = { ...area }
  delete payload.id
  try {
    const { data } = await requestApi.put(`/api/v1/Building/UpdateArea/${area.id}`, payload);
    dispatch({ type: UPDATE_BUILDING_AREA_SUCCESS, area });
    toast.success('saved!');
  } catch (err) {
    dispatch({ type: UPDATE_BUILDING_AREA_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const duplicateBuildingArea = ({ area, buildingId }) => async dispatch => {
  dispatch({ type: CREATE_BUILDING_AREA_START, payload: area });
  try {
    const { data } = await requestApi.post(`/api/v1/Building/DuplicateArea/${buildingId}`, area);
    dispatch({ type: CREATE_BUILDING_AREA_SUCCESS, area: data });
    toast.success('saved!');
  } catch (err) {
    dispatch({ type: CREATE_BUILDING_AREA_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const createBuildingArea = ({ area, buildingId }) => async dispatch => {
  dispatch({ type: CREATE_BUILDING_AREA_START, payload: area });
  try {
    const { data } = await requestApi.post(`/api/v1/Building/AddArea/${buildingId}`, area);
    dispatch({ type: CREATE_BUILDING_AREA_SUCCESS, area: data });
    // toast.success('saved!');
    return data;
  } catch (err) {
    dispatch({ type: CREATE_BUILDING_AREA_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

// export const updateBuildingArea = ({ area }) => async dispatch => {
//   dispatch({ type: UPDATE_BUILDING_AREA_START, payload: area });
//   const payload = { ...area }
//   delete payload.id
//   try {
//     const { data } = await requestApi.post(`/api/v1/Building/UpdateArea/${area.id}`, area);
//     dispatch({ type: UPDATE_BUILDING_AREA_SUCCESS, area: data });
//     // toast.success('saved!');
//     return data;
//   } catch (err) {
//     dispatch({ type: UPDATE_BUILDING_AREA_FAILURE, err });
//     toast.error(err.message);
//     return Promise.reject(err)
//   }
// }

export const deleteBuildingArea = ({ areaId }) => async dispatch => {
  dispatch({ type: DELETE_BUILDING_AREA_START, payload: areaId });
  try {
    const { data } = await requestApi.delete(`/api/v1/Building/DeleteArea/${areaId}`);
    dispatch({ type: DELETE_BUILDING_AREA_SUCCESS, area: data, id: areaId });
    // toast.success('saved!');
  } catch (err) {
    dispatch({ type: DELETE_BUILDING_AREA_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const createBuildingCustomArea = ({ customArea, areaId }) => async dispatch => {
  dispatch({ type: CREATE_BUILDING_CUSTOM_AREA_START, payload: customArea });
  try {
    const { data } = await requestApi.post(`/api/v1/Building/AddCustomArea/${areaId}`, customArea);
    dispatch({ type: CREATE_BUILDING_CUSTOM_AREA_SUCCESS, area: data, floorId: areaId });
    toast.success('saved!');
    return data;
  } catch (err) {
    dispatch({ type: CREATE_BUILDING_CUSTOM_AREA_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const updateBuildingCustomArea = ({ customArea, floorId }) => async dispatch => {
  dispatch({ type: UPDATE_BUILDING_CUSTOM_AREA_START, payload: customArea });
  const payload = { ...customArea }
  delete payload.id
  try {
    const { data } = await requestApi.put(`/api/v1/Building/UpdateCustomArea/${customArea.id}`, payload);
    dispatch({ type: UPDATE_BUILDING_CUSTOM_AREA_SUCCESS, area: customArea, floorId });
    toast.success('saved!');
    return data;
  } catch (err) {
    dispatch({ type: UPDATE_BUILDING_CUSTOM_AREA_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const updateBuildingCustomAreaOrder = ({ buildingId, floors }) => async dispatch => {
  dispatch({ type: UPDATE_BUILDING_CUSTOM_AREA_ORDER_START, floors });
  try {
    const { data } = await requestApi.put(`/api/v1/Building/UpdateFloor/${buildingId}`, { floors });
    dispatch({ type: UPDATE_BUILDING_CUSTOM_AREA_ORDER_SUCCESS, floors });
    toast.success('saved!');
    return data;
  } catch (err) {
    dispatch({ type: UPDATE_BUILDING_CUSTOM_AREA_ORDER_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}

export const deleteBuildingCustomArea = ({ customAreaId, floorId }) => async dispatch => {
  dispatch({ type: DELETE_BUILDING_CUSTOM_AREA_START, payload: customAreaId });
  try {
    const { data } = await requestApi.delete(`/api/v1/Building/DeleteCustomArea/${customAreaId}`);
    dispatch({ type: DELETE_BUILDING_CUSTOM_AREA_SUCCESS, customAreaId, floorId });
    toast.success('deleted!');
  } catch (err) {
    dispatch({ type: DELETE_BUILDING_CUSTOM_AREA_FAILURE, err });
    toast.error(err.message);
    return Promise.reject(err)
  }
}
import { GET_QR_CODES_FAILURE, GET_QR_CODES_START, GET_QR_CODES_SUCCESS } from '../../actionTypes';

const initialState = {
  all: [],
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_QR_CODES_START: {
      return { ...state, loading: true }
    }
    case GET_QR_CODES_SUCCESS: {
      return { ...state, loading: false, all: action.qrCodes }
    }
    case GET_QR_CODES_FAILURE: {
      return { ...state, loading: false, error: action.err }
    }

    default: {
      return state
    }
  }
}

import React, { Component, useState } from "react"
import { Input } from "reactstrap"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import ReactPaginate from "react-paginate"
import { history } from '../../history'
import {
  ChevronDown,
  Check,
  ChevronLeft,
  ChevronRight
} from "react-feather"
import Sidebar from "./DataListSidebar"
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy"
import ComponentSpinner from 'components/@vuexy/spinner/Loading-spinner';

import "../../assets/scss/plugins/extensions/react-paginate.scss"
import "../../assets/scss/pages/data-list.scss"
import Button from "components/Button"

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important"
      }
    }
  }
}

const CustomHeader = props => {
  const [typingTimer, setTypingTimer] = useState();
  const [input, setInput] = useState(props.query || '');

  const doneTyping = value => props.handleFilter(value);

  const handleChange = (e) => {
    const value = e.target.value;
    setInput(value);
    clearTimeout(typingTimer);
    setTypingTimer(setTimeout(() => doneTyping(value), 350));
  }

  return (
    <div className="data-list-header d-flex justify-content-between flex-wrap">
      {
        props.newButton && (
          <div className="actions action-btns" style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}>
            <div className="dt-buttons btn-group">
              <Button
                outline
                style={{ padding: '0.9rem 0.938rem' }}
                onClick={props.newButtonOnClick}
              >
                + Add New
              </Button>
            </div>
          </div>
        )
      }
      <div className="actions-left d-flex flex-wrap">
        {props.customHeader}
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        {
          props.hideSearch ? null :
            <div className="filter-section">
              <Input type="text" value={input} onChange={handleChange} />
            </div>
        }
      </div>
    </div>
  )
}

class DataListConfig extends Component {

  state = {
    data: [],
    totalPages: 0,
    currentPage: 0,
    allData: [],
    value: "",
    rowsPerPage: 10,
    sidebar: false,
    currentData: null,
    selected: [],
    totalRecords: 0,
    sortIndex: [],
    addNew: ""
  }

  thumbView = this.props.thumbView

  componentDidMount() {
    const {
      getData,
      parsedFilter,
      // page
    } = this.props
    getData(parsedFilter)
  }

  handleFilter = value => {
    this.setState({ value })
    this.props.handleFilter(value)
    // this.props.filterData(e.target.value)
  }

  handleRowsPerPage = value => {
    // let { parsedFilter, getData, currentPage } = this.props
    // let page = parsedFilter.page !== undefined ? parsedFilter.page : 1
    // history.push(`${currentPage}?page=${page}&per_page=${value}`)
    // this.setState({ rowsPerPage: value })
    // getData({ page: parsedFilter.page, per_page: value })
    this.props.setRowsPerPage(value)
  }

  handleSidebar = (boolean, addNew = false) => {
    this.setState({ sidebar: boolean })
    if (addNew === true) this.setState({ currentData: null, addNew: true })
  }


  handleDelete = row => {
    // this.props.deleteData(row)
    this.props.getData(this.props.parsedFilter)
    if (this.state.data.length - 1 === 0) {
      let urlPrefix = this.props.thumbView
        ? "/data-list/thumb-view/"
        : "/data-list/list-view/"
      history.push(
        `${urlPrefix}list-view?page=${parseInt(
          this.props.parsedFilter.page - 1
        )}&per_page=${this.props.parsedFilter.per_page}`
      )
      this.props.getData({
        page: this.props.parsedFilter.page - 1,
        per_page: this.props.parsedFilter.per_page
      })
    }
  }

  handlePagination = page => {
    let {
      // parsedFilter,
      // getData,
      setPage,
    } = this.props;
    // getData({ page: page.selected + 1 }, page.selected + 1)
    setPage(page.selected + 1);

  }


  render() {
    const {
      columns,
      data,
      currentData,
      setSidebar,
      sidebar,
      sidebarSubmitText,
      form,
      handleSubmit,
      onRowClicked,
      newButton,
      newButtonOnClick,
      sidebarTitle,
      loading,
      totalPages,
      rowsPerPage,
      big = false,
      selectable,
      onSort,
      disableSubmit = false,
    } = this.props;

    let {
      // totalPages,
      totalRecords,
      sortIndex
    } = this.state;

    if (loading) {
      return <ComponentSpinner />
    }

    return (
      <div className='data-list list-view'>
        <DataTable
          onRowClicked={onRowClicked}
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationComponent={() => (
            <ReactPaginate
              previousLabel={<ChevronLeft size={15} />}
              nextLabel={<ChevronRight size={15} />}
              breakLabel="..."
              breakClassName="break-me"
              pageCount={totalPages}
              containerClassName="vx-pagination separated-pagination pagination-center pagination-sm mb-0 mt-2"
              activeClassName="active"
              forcePage={
                this.props.page
                  ? parseInt(this.props.page - 1)
                  : 0
              }
              onPageChange={page => this.handlePagination(page)}
            />
          )}
          noHeader
          subHeader
          responsive
          selectableRows={selectable}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm"
          }}
          selectableRowsHighlight
          onSelectedRowsChange={data =>
            this.setState({ selected: data.selectedRows })
          }
          pointerOnHover
          customStyles={selectedStyle}
          subHeaderComponent={
            <CustomHeader
              customHeader={this.props.customHeader}
              handleSidebar={setSidebar}
              handleFilter={this.handleFilter}
              handleRowsPerPage={this.handleRowsPerPage}
              rowsPerPage={rowsPerPage}
              total={totalRecords}
              index={sortIndex}
              newButton={newButton}
              newButtonOnClick={newButtonOnClick}
              query={this.props.query}
              hideSearch={this.props.hideSearch}
            />
          }
          sortIcon={<ChevronDown />}
          sortServer
          onSort={onSort}
        />

        <Sidebar
          show={sidebar}
          big={big}
          data={currentData}
          form={form}
          disableSubmit={disableSubmit}
          handleSubmit={handleSubmit}
          handleSidebar={setSidebar}
          thumbView={this.props.thumbView}
          dataParams={this.props.parsedFilter}
          addNew={this.state.addNew}
          submitText={sidebarSubmitText}
          title={sidebarTitle}
        />
        <div
          className={classnames("data-list-overlay", {
            show: sidebar
          })}
          onClick={() => setSidebar(false)}
        />
      </div>
    )
  }
}

export default DataListConfig

import {
  GET_COMPANIES_START,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_ACCOUNTS_BY_MONTH_SUCCESS,
  GET_ACCOUNTS_BY_MONTH_START,
  GET_ACCOUNTS_BY_MONTH_FAILURE,
  LOGOUT_SUCCESS,
} from "redux/actionTypes"

const initialState = {
  loading: false,
  all: [],
  companiesByMonth: [],
  err: null,
  companiesLoaded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES_START: 
    case GET_ACCOUNTS_BY_MONTH_START: 
      return { ...state, loading: true }
    
    case GET_ACCOUNTS_BY_MONTH_SUCCESS: 
      return {
        ...state, companiesByMonth: action.data
      }
    case GET_COMPANIES_SUCCESS: 
      return { ...state, loading: false, all: action.payload, companiesLoaded: true, }
    case GET_ACCOUNTS_BY_MONTH_FAILURE:
      return { ...state, loading: false }
    case GET_COMPANIES_FAILURE: 
      return { loading: false, err: action.payload }
    case LOGOUT_SUCCESS: 
      return { ...initialState }
    default: 
      return state
    
  }
}

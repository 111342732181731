import {
  GET_REGION_SETTINGS_START,
  GET_REGION_SETTINGS_SUCCESS,
  GET_REGION_SETTINGS_FAILURE,
  SAVE_REGION_SETTINGS_START,
  SAVE_REGION_SETTINGS_SUCCESS,
  SAVE_REGION_SETTINGS_FAILURE,
  LOGOUT_SUCCESS,
} from 'redux/actionTypes';

const initialState = {
  error: null,
  loading: false,
  userId: "",
  groups: true,
  tags: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGION_SETTINGS_START: {
      return { ...state, loading: true }
    }
    case GET_REGION_SETTINGS_SUCCESS: {
      return { ...state, loading: false, ...action.payload }
    }
    case GET_REGION_SETTINGS_FAILURE: {
      return { ...state, loading: false, error: action.err }
    }

    case LOGOUT_SUCCESS: 
    return { ...initialState }

    case SAVE_REGION_SETTINGS_START: {
      return state
    }
    case SAVE_REGION_SETTINGS_SUCCESS: {
      return { ...state, ...action.payload }
    }
    case SAVE_REGION_SETTINGS_FAILURE: {
      return { ...state, error: action.err }
    }

    default: {
      return state
    }
  }
}

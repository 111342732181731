import {
  CREATE_DEMO_ACCOUNT_FAILURE,
  CREATE_DEMO_ACCOUNT_START,
  CREATE_DEMO_ACCOUNT_SUCCESS,
} from '../../actionTypes';

const initialState = {
  loading: true,
  err: null,
}

const demoReducers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DEMO_ACCOUNT_START:
      return { ...state, loading: true }
    case CREATE_DEMO_ACCOUNT_SUCCESS:
      return { ...state, loading: false, err: null }
    case CREATE_DEMO_ACCOUNT_FAILURE:
      return { ...state, loading: false, err: action.err }
    default:
      return state
  }
}

export default demoReducers

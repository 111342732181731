// USERS
export const GET_TOTAL_USERS_BY_MONTHS_START =
  "GET_TOTAL_USERS_BY_MONTHS_START";
export const GET_TOTAL_USERS_BY_MONTHS_SUCCESS =
  "GET_TOTAL_USERS_BY_MONTHS_SUCCESS";
export const GET_TOTAL_USERS_BY_MONTHS_FAILURE =
  "GET_TOTAL_USERS_BY_MONTHS_FAILURE";
export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const CREATE_SINGLE_USER_STARTED = "CREATE_SINGLE_USER_STARTED";
export const CREATE_SINGLE_USER_SUCCESS = "CREATE_SINGLE_USER_SUCCESS";
export const CREATE_SINGLE_USER_FAILURE = "CREATE_SINGLE_USER_FAILURE";
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// ACCOUNTS
export const GET_ACCOUNTS_START = "GET_ACCOUNTS_START";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILURE";
export const CREATE_ACCOUNT_START = "CREATE_ACCOUNT_START";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAILURE = "CREATE_ACCOUNT_FAILURE";
export const UPDATE_ACCOUNT_START = "UPDATE_ACCOUNT_START";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";
export const GET_ACCOUNTS_BY_MONTH_START = "GET_ACCOUNTS_BY_MONTH_START";
export const GET_ACCOUNTS_BY_MONTH_SUCCESS = "GET_ACCOUNTS_BY_MONTH_SUCCESS";
export const GET_ACCOUNTS_BY_MONTH_FAILURE = "GET_ACCOUNTS_BY_MONTH_FAILURE";

// DEVICES
export const GET_DEVICES_START = "GET_DEVICES_START";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const GET_DEVICES_FAILURE = "GET_DEVICES_FAILURE";
export const CREATE_DEVICE_START = "CREATE_DEVICE_START";
export const CREATE_DEVICE_SUCCESS = "CREATE_DEVICE_SUCCESS";
export const CREATE_DEVICE_FAILURE = "CREATE_DEVICE_FAILURE";
export const EDIT_DEVICE_START = "EDIT_DEVICE_START";
export const EDIT_DEVICE_SUCCESS = "EDIT_DEVICE_SUCCESS";
export const EDIT_DEVICE_FAILURE = "EDIT_DEVICE_FAILURE";
export const UPDATE_DEVICE_START = "UPDATE_DEVICE_START";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
export const UPDATE_DEVICE_FAILURE = "UPDATE_DEVICE_FAILURE";
export const GET_DEVICES_BY_MONTH_START = "GET_DEVICES_BY_MONTH_START";
export const GET_DEVICES_BY_MONTH_SUCCESS = "GET_DEVICES_BY_MONTH_SUCCESS";
export const GET_DEVICES_BY_MONTH_FAILURE = "GET_DEVICES_BY_MONTH_FAILURE";

// AUTH
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FETCH_ME_START = "FETCH_ME_START";
export const FETCH_ME_SUCCESS = "FETCH_ME_SUCCESS";
export const FETCH_ME_FAILURE = "FETCH_ME_FAILURE";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// GENERAL SETTINGS
export const GET_GENERAL_SETTINGS_START = "GET_GENERAL_SETTINGS_START";
export const GET_GENERAL_SETTINGS_SUCCESS = "GET_GENERAL_SETTINGS_SUCCESS";
export const GET_GENERAL_SETTINGS_FAILURE = "GET_GENERAL_SETTINGS_FAILURE";
export const SAVE_GENERAL_SETTINGS_START = "SAVE_GENERAL_SETTINGS_START";
export const SAVE_GENERAL_SETTINGS_SUCCESS = "SAVE_GENERAL_SETTINGS_SUCCESS";
export const SAVE_GENERAL_SETTINGS_FAILURE = "SAVE_GENERAL_SETTINGS_FAILURE";

// ESTIMATE SETTINGS
export const GET_ESTIMATE_SETTINGS_START = "GET_ESTIMATE_SETTINGS_START";
export const GET_ESTIMATE_SETTINGS_SUCCESS = "GET_ESTIMATE_SETTINGS_SUCCESS";
export const GET_ESTIMATE_SETTINGS_FAILURE = "GET_ESTIMATE_SETTINGS_FAILURE";
export const SAVE_ESTIMATE_SETTINGS_START = "SAVE_ESTIMATE_SETTINGS_START";
export const SAVE_ESTIMATE_SETTINGS_SUCCESS = "SAVE_ESTIMATE_SETTINGS_SUCCESS";
export const SAVE_ESTIMATE_SETTINGS_FAILURE = "SAVE_ESTIMATE_SETTINGS_FAILURE";

// REGION SETTINGS
export const GET_REGION_SETTINGS_START = "GET_REGION_SETTINGS_START";
export const GET_REGION_SETTINGS_SUCCESS = "GET_REGION_SETTINGS_SUCCESS";
export const GET_REGION_SETTINGS_FAILURE = "GET_REGION_SETTINGS_FAILURE";
export const SAVE_REGION_SETTINGS_START = "SAVE_REGION_SETTINGS_START";
export const SAVE_REGION_SETTINGS_SUCCESS = "SAVE_REGION_SETTINGS_SUCCESS";
export const SAVE_REGION_SETTINGS_FAILURE = "SAVE_REGION_SETTINGS_FAILURE";

// NOTIFICATION SETTINGS
export const GET_NOTIFICATION_SETTINGS_START =
  "GET_NOTIFICATION_SETTINGS_START";
export const GET_NOTIFICATION_SETTINGS_SUCCESS =
  "GET_NOTIFICATION_SETTINGS_SUCCESS";
export const GET_NOTIFICATION_SETTINGS_FAILURE =
  "GET_NOTIFICATION_SETTINGS_FAILURE";
export const SAVE_NOTIFICATION_SETTINGS_START =
  "SAVE_NOTIFICATION_SETTINGS_START";
export const SAVE_NOTIFICATION_SETTINGS_SUCCESS =
  "SAVE_NOTIFICATION_SETTINGS_SUCCESS";
export const SAVE_NOTIFICATION_SETTINGS_FAILURE =
  "SAVE_NOTIFICATION_SETTINGS_FAILURE";

// REPORT SETTINGS
export const GET_REPORT_SETTINGS_START = "GET_REPORT_SETTINGS_START";
export const GET_REPORT_SETTINGS_SUCCESS = "GET_REPORT_SETTINGS_SUCCESS";
export const GET_REPORT_SETTINGS_FAILURE = "GET_REPORT_SETTINGS_FAILURE";
export const SAVE_REPORT_SETTINGS_START = "SAVE_REPORT_SETTINGS_START";
export const SAVE_REPORT_SETTINGS_SUCCESS = "SAVE_REPORT_SETTINGS_SUCCESS";
export const SAVE_REPORT_SETTINGS_FAILURE = "SAVE_REPORT_SETTINGS_FAILURE";

// COMPANIES
export const GET_COMPANIES_START = "GET_COMPANIES_START";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";

// LOCATIONS
export const CREATE_LOCATION_START = "CREATE_LOCATION_START";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_FAILURE = "CREATE_LOCATION_FAILURE";
export const DELETE_LOCATION_START = "DELETE_LOCATION_START";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILURE = "DELETE_LOCATION_FAILURE";

// BUILDINGS
export const CREATE_BUILDING_START = "CREATE_BUILDING_START";
export const CREATE_BUILDING_SUCCESS = "CREATE_BUILDING_SUCCESS";
export const CREATE_BUILDING_FAILURE = "CREATE_BUILDING_FAILURE";
export const UPDATE_BUILDING_START = "UPDATE_BUILDING_START";
export const UPDATE_BUILDING_SUCCESS = "UPDATE_BUILDING_SUCCESS";
export const UPDATE_BUILDING_FAILURE = "UPDATE_BUILDING_FAILURE";
export const GET_BUILDINGS_START = "GET_BUILDINGS_START";
export const GET_BUILDINGS_SUCCESS = "GET_BUILDINGS_SUCCESS";
export const GET_BUILDINGS_FAILURE = "GET_BUILDINGS_FAILURE";
export const GET_BUILDING_START = "GET_BUILDING_START";
export const GET_BUILDING_SUCCESS = "GET_BUILDING_SUCCESS";
export const GET_BUILDING_FAILURE = "GET_BUILDING_FAILURE";
export const SET_CURRENT_BUILDING = "SET_CURRENT_BUILDING";
export const CREATE_BUILDING_AREA_START = "CREATE_BUILDING_AREA_START";
export const CREATE_BUILDING_AREA_SUCCESS = "CREATE_BUILDING_AREA_SUCCESS";
export const CREATE_BUILDING_AREA_FAILURE = "CREATE_BUILDING_AREA_FAILURE";
export const UPDATE_BUILDING_AREA_START = "UPDATE_BUILDING_AREA_START";
export const UPDATE_BUILDING_AREA_SUCCESS = "UPDATE_BUILDING_AREA_SUCCESS";
export const UPDATE_BUILDING_AREA_FAILURE = "UPDATE_BUILDING_AREA_FAILURE";
export const DELETE_BUILDING_AREA_START = "DELETE_BUILDING_AREA_START";
export const DELETE_BUILDING_AREA_SUCCESS = "DELETE_BUILDING_AREA_SUCCESS";
export const DELETE_BUILDING_AREA_FAILURE = "DELETE_BUILDING_AREA_FAILURE";
export const CREATE_BUILDING_CUSTOM_AREA_START =
  "CREATE_BUILDING_CUSTOM_AREA_START";
export const CREATE_BUILDING_CUSTOM_AREA_SUCCESS =
  "CREATE_BUILDING_CUSTOM_AREA_SUCCESS";
export const CREATE_BUILDING_CUSTOM_AREA_FAILURE =
  "CREATE_BUILDING_CUSTOM_AREA_FAILURE";
export const UPDATE_BUILDING_CUSTOM_AREA_START =
  "UPDATE_BUILDING_CUSTOM_AREA_START";
export const UPDATE_BUILDING_CUSTOM_AREA_SUCCESS =
  "UPDATE_BUILDING_CUSTOM_AREA_SUCCESS";
export const UPDATE_BUILDING_CUSTOM_AREA_FAILURE =
  "UPDATE_BUILDING_CUSTOM_AREA_FAILURE";
export const DELETE_BUILDING_CUSTOM_AREA_START =
  "DELETE_BUILDING_CUSTOM_AREA_START";
export const DELETE_BUILDING_CUSTOM_AREA_SUCCESS =
  "DELETE_BUILDING_CUSTOM_AREA_SUCCESS";
export const DELETE_BUILDING_CUSTOM_AREA_FAILURE =
  "DELETE_BUILDING_CUSTOM_AREA_FAILURE";
export const UPDATE_BUILDING_CUSTOM_AREA_ORDER_START =
  "UPDATE_BUILDING_CUSTOM_AREA_ORDER_START";
export const UPDATE_BUILDING_CUSTOM_AREA_ORDER_SUCCESS =
  "UPDATE_BUILDING_CUSTOM_AREA_ORDER_SUCCESS";
export const UPDATE_BUILDING_CUSTOM_AREA_ORDER_FAILURE =
  "UPDATE_BUILDING_CUSTOM_AREA_ORDER_FAILURE";
export const GET_BUILDINGS_TAGS_BY_CUSTOMER_START =
  "GET_BUILDINGS_TAGS_BY_CUSTOMER_START";
export const GET_BUILDINGS_TAGS_BY_CUSTOMER_SUCCESS =
  "GET_BUILDINGS_TAGS_BY_CUSTOMER_SUCCESS";
export const GET_BUILDINGS_TAGS_BY_CUSTOMER_FAILURE =
  "GET_BUILDINGS_TAGS_BY_CUSTOMER_FAILURE";

// TAGS
export const GET_TAGS_START = "GET_TAGS_START";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE;";
export const ADD_TAG_CATEGORY_START = "ADD_TAG_CATEGORY_START";
export const ADD_TAG_CATEGORY_SUCCESS = "ADD_TAG_CATEGORY_SUCCESS";
export const ADD_TAG_CATEGORY_FAILURE = "ADD_TAG_CATEGORY_FAILURE;";
export const ADD_TAG_START = "ADD_TAG_START";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAILURE = "ADD_TAG_FAILURE;";
export const REMOVE_TAG_START = "REMOVE_TAG_START";
export const REMOVE_TAG_SUCCESS = "REMOVE_TAG_SUCCESS";
export const REMOVE_TAG_FAILURE = "REMOVE_TAG_FAILURE;";

// PERMISSIONS
export const GET_PERMISSIONS_START = "GET_PERMISSIONS_START";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE;";
export const GET_ROLES_START = "GET_ROLES_START";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE;";
export const UPDATE_ROLE_START = "UPDATE_ROLE_START";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE;";
export const CREATE_ROLE_START = "CREATE_ROLE_START";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE;";

// Subscription
export const PAY_SUBSCRIPTION_START = "PAY_SUBSCRIPTION_START";
export const PAY_SUBSCRIPTION_SUCCESS = "PAY_SUBSCRIPTION_SUCCESS";
export const PAY_SUBSCRIPTION_FAILURE = "PAY_SUBSCRIPTION_FAILURE;";
export const GET_SUBSCRIPTION_HISTORY_START = "GET_SUBSCRIPTION_HISTORY_START";
export const GET_SUBSCRIPTION_HISTORY_SUCCESS = "GET_SUBSCRIPTION_HISTORY_SUCCESS";
export const GET_SUBSCRIPTION_HISTORY_FAILURE = "GET_SUBSCRIPTION_HISTORY_FAILURE;";

// QR CODES
export const GET_QR_CODES_START = "GET_QR_CODES_START";
export const GET_QR_CODES_SUCCESS = "GET_QR_CODES_SUCCESS";
export const GET_QR_CODES_FAILURE = "GET_QR_CODES_FAILURE;";

// DEMO
export const CREATE_DEMO_ACCOUNT_START = "CREATE_DEMO_ACCOUNT_START";
export const CREATE_DEMO_ACCOUNT_SUCCESS = "CREATE_DEMO_ACCOUNT_SUCCESS";
export const CREATE_DEMO_ACCOUNT_FAILURE = "CREATE_DEMO_ACCOUNT_FAILURE;";

import React from "react"
import * as Icon from "react-feather"
import { permitsEnum } from "views/pages/users/Users"

const horizontalMenuConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Grid size={20} />,
    navLink: '/'
  },
  {
    type: "groupHeader",
    groupTitle: "MY ACCOUNT"
  },
  {
    id: "account-settings",
    title: "Account Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    // permissions: ["admin"],
    navLink: "/account-settings",
    // filterBase: "/email/inbox"
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: [permitsEnum.users],
    navLink: "/users"
  },
  {
    id: "devices",
    title: "Devices",
    type: "item",
    icon: <Icon.Smartphone size={20} />,
    // permissions: ["admin", "editor"],
    navLink: "/devices",
  },
  {
    type: "groupHeader",
    groupTitle: "BILLING",
    permissions: [permitsEnum.audits]
  },
  {
    id: "subscription",
    title: "Subscription",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    navLink: "/billing-subscription"
  },
  {
    id: "invoices",
    title: "Invoices",
    type: "item",
    icon: <Icon.ShoppingCart size={20} />,
    navLink: "/billing-invoices"
  },
  {
    type: "groupHeader",
    groupTitle: "AUDITS & ESTIMATES",
    permissions: [permitsEnum.audits],
  },
  {
    id: "accounts",
    title: "Accounts",
    type: "item",
    icon: <Icon.MapPin size={20} />,
    navLink: "/accounts",
    permissions: [permitsEnum.audits],
  },
  {
    id: "analytics",
    title: "Analytics",
    type: "item",
    icon: <Icon.BarChart size={20} />,
    permissions: [permitsEnum.audits],
    navLink: "/analytics"
  },
  {
    id: "audits",
    title: "Audits",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: [permitsEnum.audits],
    navLink: "/audits"
  },
  {
    id: "estimates",
    title: "Estimates",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    permissions: [permitsEnum.audits],
    navLink: "/estimates"
  },
    {
    id: "schedule",
    title: "Schedule",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    permissions: [permitsEnum.audits],
    navLink: "/schedule"
  },
  {
    type: "groupHeader",
    groupTitle: "SUPPORT"
  },
  {
    id: "faq",
    title: "FAQ",
    type: "item",
    icon: <Icon.HelpCircle size={20} />,
    // permissions: ["admin", "editor"],
    navLink: "/faq"
  },
  {
    id: "tutorial",
    title: "Tutorial",
    type: "item",
    icon: <Icon.Info size={20} />,
    // permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "help-desk",
    title: "Help Desk",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    // permissions: ["admin", "editor"],
    navLink: "/"
  },
]

export default horizontalMenuConfig

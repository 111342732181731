import React, { Component } from "react"
import { FormGroup, Button } from "reactstrap"
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"

class DataListSidebar extends Component {
  state = {
    id: "",
    name: "",
    category: "Audio",
    order_status: "pending",
    price: "",
    img: "",
    popularity: {
      popValue: ""
    }
  }

  goToSubmit = () => {
    this.props.redirect()
  }

  addNew = false

  render() {
    const { show, handleSidebar, big, data, form, handleSubmit, submitText, title, summaryBtn } = this.props
    const { name, img } = this.state
    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
          big: big,
        })}>
        <form>
          <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
            <h4>{title || (data !== null ? "UPDATE DATA" : "ADD NEW DATA")}</h4>
            <X size={20} onClick={() => handleSidebar(false, true)} />
          </div>
          <PerfectScrollbar
            className="data-list-fields px-2 mt-3"
            options={{ wheelPropagation: false }}>
            {this.props.thumbView && img.length ? (
              <FormGroup className="text-center">
                <img className="img-fluid" src={img} alt={name} />
                <div className="d-flex flex-wrap justify-content-between mt-2">
                  <label
                    className="btn btn-flat-primary"
                    htmlFor="update-image"
                    color="primary">
                    Upload Image
                  <input
                      type="file"
                      id="update-image"
                      hidden
                      onChange={e =>
                        this.setState({
                          img: URL.createObjectURL(e.target.files[0])
                        })
                      }
                    />
                  </label>
                  <Button
                    color="flat-danger"
                    onClick={() => this.setState({ img: "" })}>
                    Remove Image
                </Button>
                </div>
              </FormGroup>
            ) : null}
            {show && form}
            {this.props.thumbView && img.length <= 0 ? (
              <label
                className="btn btn-primary"
                htmlFor="upload-image"
                color="primary">
                Upload Image
                <input
                  type="file"
                  id="upload-image"
                  hidden
                  onChange={e =>
                    this.setState({ img: URL.createObjectURL(e.target.files[0]) })
                  }
                />
              </label>
            ) : null}
          </PerfectScrollbar>
          <div className="data-list-sidebar-footer px-2 d-flex justify-content-around align-items-center mt-1">
            <Button color="primary" onClick={handleSubmit} disabled={this.props.disableSubmit}>
              {submitText || (data !== null ? "Update" : "Submit")}
            </Button>
            {summaryBtn && 
              <Button color="primary" onClick={this.goToSubmit}>
                Summary
              </Button>
            }
            <Button
              className="ml-1"
              color="danger"
              outline
              onClick={() => handleSidebar(false)}>
              Cancel
          </Button>
          </div>
        </form>
      </div>
    )
  }
}
export default DataListSidebar

import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_ME_START,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "redux/actionTypes"
import cookies from 'utility/cookieHandler';

const initialState = {
  loginIn: false,
  loginError: null,
  currentUser: null,
  logged: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START: {
      return { ...state, loginIn: true }
    }
    case LOGIN_SUCCESS: {
      return { ...state, loginIn: false, currentUser: action.user, logged: true }
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        loginIn: false,
        loginError: action.err,
        logged: false,
        currentUser: initialState.currentUser,
        loginOut: false,
        loadingAuth: false,
      }
    }
    case LOGOUT_START: {
      return { ...state, loginOut: true }
    }
    case LOGOUT_SUCCESS: 
      return { ...initialState }
      
    case LOGOUT_FAILURE: {
      return { ...state, loginOut: false, logoutError: action.err }
    }
    case FETCH_ME_START: {
      return { ...state, loadingAuth: true }
    }
    case FETCH_ME_SUCCESS: {
      return { ...state, loadingAuth: false, currentUser: action.user, logged: true }
    }
    case FETCH_ME_FAILURE: {
      return {
        ...state,
        loginIn: false,
        loginError: action.err,
        logged: false,
        loadingAuth: false,
        currentUser: initialState.currentUser,
      }
    }
    default: {
      return state
    }
  }
}

import {
  GET_PERMISSIONS_START,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_START,
} from 'redux/actionTypes';

const initialState = {
  error: null,
  loading: false,
  loadingRoles: false,
  all: [],
  roles: [],
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_PERMISSIONS_START: {
      return { ...state, loading: true }
    }
    case GET_PERMISSIONS_SUCCESS: {
      return { ...state, loading: false, all: action.permissions }
    }
    case GET_PERMISSIONS_FAILURE: {
      return { ...state, loading: false, error: action.err }
    }

    case GET_ROLES_START: {
      return { ...state, loadingRoles: true }
    }
    case GET_ROLES_SUCCESS: {
      return { ...state, loadingRoles: false, roles: action.roles }
    }
    case GET_ROLES_FAILURE: {
      return { ...state, loadingRoles: false, error: action.err }
    }

    case UPDATE_ROLE_START: {
      {
        const role = state.roles.find(rol => rol.id === action.roleId);
        if (action.permissions) {
          role.permissions = action.permissions.map(id => ({ id }));
        }
        if (typeof action.description === 'string' || action.description instanceof String) {
          role.description = action.description
        }
      }
      return { ...state, updatingRole: true, roles: [...state.roles] }
    }
    case UPDATE_ROLE_SUCCESS: {
      return { ...state, updatingRole: false }
    }
    // case GET_ROLES_FAILURE: {
    //   return { ...state, loadingRoles: false, error: action.err }
    // }

    default: {
      return state
    }
  }
}

import React from 'react'
import * as Icon from 'react-feather'

const navigationConfig = [
  {
    type: 'groupHeader',
    groupTitle: 'SCJP Team',
    dark: true,
  },
  {
    id: 'request-form',
    title: 'New Subscriber',
    type: 'item',
    icon: <Icon.Edit size={20} />,
    dark: true,
    navLink: '/partner-request-form',
  },
  {
    id: 'request-list',
    title: 'Subscribers',
    type: 'item',
    icon: <Icon.Flag size={20} />,
    dark: true,
    last: true,
    navLink: '/partners',
  },
  {
    id: 'demo-account',
    title: 'Demo Accounts',
    type: 'item',
    icon: <Icon.Youtube size={20} />,
    dark: true,
    navLink: '/demo-account',
  },
  {
    id: 'qr-codes',
    title: 'QR Codes',
    type: 'item',
    icon: <Icon.Grid size={20} />,
    dark: true,
    last: true,
    navLink: '/qr-codes',
  },
  {
    type: 'groupHeader',
    groupTitle: '',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Grid size={20} />,
    navLink: '/',
  },
  {
    type: 'groupHeader',
    groupTitle: 'MY ACCOUNT',
  },
  {
    id: 'account-settings',
    title: 'Account Settings',
    type: 'item',
    icon: <Icon.Settings size={20} />,
    permissions: ['demoUser', 'Access Account Settings', 'Super Admin'],
    navLink: '/account-settings',
  },
  {
    id: 'users',
    title: 'Users',
    type: 'item',
    icon: <Icon.User size={20} />,
    permissions: ['demoUser', 'View Users', 'Add/Edit Users', 'Super Admin'],
    navLink: '/users',
  },
  {
    id: 'roles',
    title: 'Roles',
    type: 'item',
    icon: <Icon.Award size={20} />,
    permissions: ['demoUser', 'View Roles', 'Super Admin'],
    navLink: '/roles',
  },
  {
    id: 'devices',
    title: 'Devices',
    type: 'item',
    icon: <Icon.Smartphone size={20} />,
    permissions: ['demoUser', 'View Mobile Devices', 'Super Admin'],
    navLink: '/devices',
  },
  {
    id: 'tags',
    title: 'Tags',
    type: 'item',
    icon: <Icon.Tag size={20} />,
    permissions: ['demoUser', 'View Tags', 'Add/Edit Tags', 'Super Admin'],
    navLink: '/tags',
  },
  {
    type: 'groupHeader',
    groupTitle: 'BILLING',
    permissions: ['demoUser', 'Make a Payment', 'View Invoices', 'View Subscription Information', 'Super Admin'],
  },
  {
    id: 'subscription',
    title: 'Subscription',
    icon: <Icon.CreditCard size={20} />,
    permissions: ['demoUser', 'View Subscription Information', 'Super Admin'],
    type: 'item',
    navLink: '/billing-subscription',
    unsubscribedAccess: true,
  },
  // {
  //   id: "invoices",
  //   title: "Invoices",
  //   icon: <Icon.ShoppingCart size={20} />,
  //   permissions: ["demoUser", 'View Invoices'],
  //   type: "item",
  //   navLink: "/billing-invoices",
  // },
  {
    type: 'groupHeader',
    groupTitle: 'CUSTOMERS & BUILDINGS',
  },
  {
    id: 'accounts',
    title: 'Customers',
    type: 'item',
    icon: <Icon.MapPin size={20} />,
    navLink: '/accounts',
    permissions: ['demoUser', 'View Customers', 'Super Admin'],
  },

  {
    id: 'buildings',
    title: 'Buildings',
    type: 'item',
    icon: <Icon.Columns size={20} />,
    navLink: '/buildings',
    permissions: ['demoUser', 'View Buildings', 'Super Admin'],
  },

  {
    type: 'groupHeader',
    groupTitle: 'AUDITS & ESTIMATES',
    // permissions: ["demoUser", permitsEnum.users],
  },
  {
    id: 'analytics',
    title: 'Analytics',
    type: 'item',
    icon: <Icon.BarChart size={20} />,
    permissions: ['demoUser', 'View Analytics'],
    navLink: '/analytics',
  },
  {
    id: 'audits',
    title: 'Audits',
    type: 'item',
    icon: <Icon.CheckSquare size={20} />,
    permissions: ['demoUser', 'View Audits', 'Super Admin'],
    navLink: '/audits',
  },
  {
    id: 'estimates',
    title: 'Estimates',
    type: 'item',
    icon: <Icon.Clipboard size={20} />,
    permissions: ['demoUser', 'View Estimates', 'Super Admin'],
    navLink: '/estimates',
  },
  {
    id: 'schedule',
    title: 'Schedule',
    type: 'item',
    icon: <Icon.Calendar size={20} />,
    permissions: ['demoUser', 'View Scheduled Audits and Estimates', 'Super Admin'],
    navLink: '/schedule',
  },
  {
    type: 'groupHeader',
    groupTitle: 'SUPPORT',
  },
  {
    id: 'faq',
    title: 'FAQ',
    type: 'item',
    icon: <Icon.HelpCircle size={20} />,
    // permissions: ["demoUser", "admin", "editor"],
    navLink: '/faq',
  },
  {
    id: 'tutorial',
    title: 'Tutorial',
    type: 'item',
    icon: <Icon.Info size={20} />,
    // permissions: ["demoUser", "admin", "editor"],
    navLink: '/tutorial',
  },
  {
    id: 'knowledge-base',
    title: 'Knowledge Base',
    type: 'item',
    icon: <Icon.Cloud size={20} />,
    // permissions: ["demoUser", "admin", "editor"],
    navLink: '/knowledge',
  },
  {
    id: 'help-desk',
    title: 'Help Desk',
    type: 'item',
    icon: <Icon.Clipboard size={20} />,
    // permissions: ["demoUser", "admin", "editor"],
    navLink: '/help-desk',
  },
]

export default navigationConfig

import {
  CREATE_BUILDING_AREA_FAILURE,
  CREATE_BUILDING_AREA_START,
  CREATE_BUILDING_AREA_SUCCESS,
  CREATE_BUILDING_CUSTOM_AREA_FAILURE,
  CREATE_BUILDING_CUSTOM_AREA_START,
  CREATE_BUILDING_CUSTOM_AREA_SUCCESS,
  CREATE_BUILDING_FAILURE,
  CREATE_BUILDING_START,
  CREATE_BUILDING_SUCCESS,
  DELETE_BUILDING_AREA_SUCCESS,
  DELETE_BUILDING_CUSTOM_AREA_SUCCESS,
  GET_BUILDINGS_FAILURE,
  GET_BUILDINGS_START,
  GET_BUILDINGS_SUCCESS,
  GET_BUILDING_FAILURE,
  GET_BUILDING_START,
  GET_BUILDING_SUCCESS,
  LOGOUT_SUCCESS,
  SET_CURRENT_BUILDING,
  UPDATE_BUILDING_AREA_SUCCESS,
  UPDATE_BUILDING_CUSTOM_AREA_ORDER_START,
  UPDATE_BUILDING_CUSTOM_AREA_ORDER_SUCCESS,
  UPDATE_BUILDING_CUSTOM_AREA_SUCCESS,
  GET_BUILDINGS_TAGS_BY_CUSTOMER_START,
  GET_BUILDINGS_TAGS_BY_CUSTOMER_SUCCESS,
  GET_BUILDINGS_TAGS_BY_CUSTOMER_FAILURE,
} from "redux/actionTypes";

const initialState = {
  all: [],
  loading: true,
  err: null,
  current: null,
  paging: {},
  buildingTagsByCustomer: [],
}

const buildingsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUILDINGS_START:
      return { ...state, loading: true }
    case GET_BUILDINGS_SUCCESS:
      return { ...state, loading: false, all: action.buildings, paging: action.paging, err: null }
    case GET_BUILDINGS_FAILURE:
      return { ...state, loading: false, err: action.err }

    case CREATE_BUILDING_START:
      return { ...state, loading: true }
    case CREATE_BUILDING_SUCCESS:
      return { ...state, loading: false, err: null }
    case CREATE_BUILDING_FAILURE:
      return { ...state, loading: false, err: action.err }

    case CREATE_BUILDING_AREA_START:
      return { ...state, loadingCreateArea: true }
    case CREATE_BUILDING_AREA_SUCCESS:
      state.current.zone.areas.push(action.area)
      return { ...state, loadingCreateArea: false, err: null }
    case CREATE_BUILDING_AREA_FAILURE:
      return { ...state, loadingCreateArea: false, err: action.err }

    case CREATE_BUILDING_CUSTOM_AREA_START:
      return { ...state, loadingCreateCustomArea: true }
    case CREATE_BUILDING_CUSTOM_AREA_SUCCESS:
      state.current.zone.areas.find(item => item.id === action.floorId).customAreas.push(action.area)
      return { ...state, loadingCreateCustomArea: false, err: null, current: { ...state.current } }
    case CREATE_BUILDING_CUSTOM_AREA_FAILURE:
      return { ...state, loadingCreateCustomArea: false, err: action.err }

    case GET_BUILDING_START:
      return { ...state, loading: true }
    case GET_BUILDING_SUCCESS:
      return { ...state, loading: false, current: action.building, err: null }
    case GET_BUILDING_FAILURE:
      return { ...state, loading: false, err: action.err }

    case DELETE_BUILDING_AREA_SUCCESS:
      state.current.zone.areas = state.current.zone.areas.filter(area => area.id !== action.id)
      return { ...state, loading: false, current: { ...state.current } }

    case UPDATE_BUILDING_CUSTOM_AREA_SUCCESS:
      if (action.floorId) {
        const floor = state.current.zone.areas.find(item => item.id === action.floorId)
        const area = floor.customAreas.find(item => item.id === action.area.id)
        Object.keys(action.area).forEach(key => area[key] = action.area[key]);
      }
      return { ...state, loading: false, current: { ...state.current } }
    case DELETE_BUILDING_CUSTOM_AREA_SUCCESS:
      {
        const floor = state.current.zone.areas.find(item => item.id === action.floorId)
        floor.customAreas = floor.customAreas.filter(item => item.id !== action.customAreaId)
      }
      return { ...state, loading: false, current: { ...state.current } }

    case UPDATE_BUILDING_AREA_SUCCESS:
      {
        const floor = state.current.zone.areas.find(item => item.id === action.area.id)
        Object.keys(action.area).forEach(key => floor[key] = action.area[key])
      }
      return { ...state, loading: false, current: { ...state.current } }

    case UPDATE_BUILDING_CUSTOM_AREA_ORDER_START:
      state.current.zone.areas = state.current.zone.areas.map(area => {
        const floor = action.floors.indexOf(area.id)
        return { ...area, floor }
      })
      return { ...state, loading: false, current: { ...state.current } }

    case SET_CURRENT_BUILDING:
      return { ...state, loading: true, current: action.building }
    
    case GET_BUILDINGS_TAGS_BY_CUSTOMER_START:
      return { ...state, loadingBuildingTags: true }
    case GET_BUILDINGS_TAGS_BY_CUSTOMER_SUCCESS:
      return { ...state, loadingBuildingTags: false, buildingTagsByCustomer: action.payload }
    case GET_BUILDINGS_TAGS_BY_CUSTOMER_FAILURE:
      return { ...state, loadingBuildingTags: false }

    case LOGOUT_SUCCESS:
      return { ...initialState }
    default:
      return state
  }
}

export default buildingsReducers;

import React from "react"
import ScrollToTop from "react-scroll-up"
import { Button } from "reactstrap"
import { ArrowUp } from "react-feather"
import classnames from "classnames"

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-right d-none d-md-block">

          <span className="copyright">Copyright © 2024
          <a href="https://scjp.com/" rel="noopener noreferrer" target="_blank"> S.C. Johnson Professional. </a>
          All rights Reserved
          <span style={{ color: "#bbb" }}> |</span>
            <a href="https://terms.scjbrands.com/en-us" rel="noopener noreferrer" target="_blank"> Terms &amp; Conditions </a>
            <span style={{ color: "#bbb" }}>|</span>
            <a href="https://privacy.scjbrands.com/en-us" rel="noopener noreferrer" target="_blank"> Privacy Policy </a>
          </span>

        </span>
      </p>
      {
        props.hideScrollToTop === false ? (
          <ScrollToTop showUnder={160}>
            <Button color="primary" className="btn-icon scroll-top">
              <ArrowUp size={15} />
            </Button>
          </ScrollToTop>
        ) : null
      }
    </footer>
  )
}

export default Footer

import { toast } from 'react-toastify'
import requestApi from 'utility/requestApi';
import {
  GET_REGION_SETTINGS_START,
  GET_REGION_SETTINGS_SUCCESS,
  GET_REGION_SETTINGS_FAILURE,
  SAVE_REGION_SETTINGS_START,
  SAVE_REGION_SETTINGS_SUCCESS,
  SAVE_REGION_SETTINGS_FAILURE,
} from 'redux/actionTypes';

export const getRegionSettings = userId => async dispatch => {
  dispatch({ type: GET_REGION_SETTINGS_START, payload: { userId } });
  try {
    const { data } = await requestApi.get(`/api/v1/Settings/${userId}/GetSegmentationSetting`);
    dispatch({ type: GET_REGION_SETTINGS_SUCCESS, payload: data });
  } catch (err) {
    if (err.response.status === 404) {
      dispatch({ type: GET_REGION_SETTINGS_SUCCESS, payload: {} });
    } else {
      dispatch({ type: GET_REGION_SETTINGS_FAILURE, err });
      return Promise.reject(err.response.data)
    }
  }
}

export const saveRegionSettings = data => async dispatch => {
  dispatch({ type: SAVE_REGION_SETTINGS_START, payload: data });
  try {
    await requestApi.post(`/api/v1/Settings/AddSegmentationSetting`, data);
    dispatch({ type: SAVE_REGION_SETTINGS_SUCCESS, payload: data });
    toast.success('Saved!');
  } catch (err) {
    dispatch({ type: SAVE_REGION_SETTINGS_FAILURE, err });
    toast.error('there was an error with this action!');
    return Promise.reject(err.response.data)
  }
}

import { toast } from 'react-toastify';
import requestApi from 'utility/requestApi';
import cookies from 'utility/cookieHandler';
import {
  LOGIN_START,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FETCH_ME_START,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from 'redux/actionTypes';

export const login = data => async dispatch => {
  dispatch({ type: LOGIN_START, payload: data });
  try {
    const {
      data: {
        id_token: token,
        refresh_token,
        userId,
      },
    } = await requestApi.post(`/signin?Password=${encodeURIComponent(data.password)}&EmailAddress=${encodeURIComponent(data.email)}`);
    cookies.setCookie('token', token);
    cookies.setCookie('refreshToken', refresh_token);
    cookies.setCookie('userId', userId);
    const { data: user } = await requestApi.get(`/api/v1/user/${userId}`);
    cookies.setCookie('partnerId', user.partnerId)
    dispatch({ type: LOGIN_SUCCESS, user });
    return dispatch(fetchMe());
  } catch (err) {
    cookies.deleteCookie('token');
    cookies.deleteCookie('refreshToken');
    cookies.deleteCookie('userId');
    toast.error('Error: Email or Password is incorrect.');
    dispatch({ type: LOGIN_FAILURE, err });
  }
}

const cookiesNames = ['token', 'refreshToken', 'userId', 'partnerId'];
export const logout = data => async dispatch => {
  dispatch({ type: LOGOUT_START, payload: data });
  try {
    cookiesNames.forEach(name => cookies.deleteCookie(name))
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (err) {
    dispatch({ type: LOGOUT_FAILURE, err });
    return Promise.reject(err.response.data)
  }
}

export const fetchMe = () => async dispatch => {
  dispatch({ type: FETCH_ME_START });
  try {
    const userId = cookies.getCookie('userId');
    if (userId) {
      const { data: user } = await requestApi.get(`/api/v1/user/${userId}`);
      if (user.role === 'Super Admin') {
        user.permissions.push({ name: 'Super Admin' });
      }
      if (user.role === 'Administrator') {
        user.permissions.push({ name: 'View Subscription Information' });
      }
      if (user.demoUser) {
        user.permissions = [{ name: 'demoUser' }];
      }
      dispatch({ type: FETCH_ME_SUCCESS, user });
    } else {
      throw new Error('User not logged in')
    }
  } catch (err) {
    dispatch({ type: FETCH_ME_FAILURE, err });
    return Promise.reject(err.response.data)
  }
}

export const changePassword = () => async dispatch => {
  dispatch({ type: CHANGE_PASSWORD_START });
  try {
    // const { data: user } = await requestApi.post(`/api/ChangePassword`);
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (err) {
    dispatch({ type: CHANGE_PASSWORD_FAILURE, err });
    return Promise.reject(err.response.data)
  }
}


import requestApi from 'utility/requestApi';

export const CHANGE_PARTNER_STATUS = 'CHANGE_PARTNER_STATUS'

export const LOAD_PARNERTS_REQUEST = 'LOAD_PARNERTS_REQUEST'
export const LOAD_PARNERTS_SUCCESS = 'LOAD_PARNERTS_SUCCESS'
export const LOAD_PARNERTS_ERROR = 'LOAD_PARNERTS_ERROR'

export const CREATE_PARNERTS_REQUEST = 'CREATE_PARNERTS_REQUEST'
export const CREATE_PARNERTS_SUCCESS = 'CREATE_PARNERTS_SUCCESS'
export const CREATE_PARNERTS_ERROR = 'CREATE_PARNERTS_ERROR'

export const LOAD_PARNERT_REQUEST = 'LOAD_PARNERT_REQUEST'
export const LOAD_PARNERT_SUCCESS = 'LOAD_PARNERT_SUCCESS'
export const LOAD_PARNERT_ERROR = 'LOAD_PARNERT_ERROR'

export const DELETE_PARNERTS_REQUEST = 'DELETE_PARNERTS_REQUEST'
export const DELETE_PARNERTS_SUCCESS = 'DELETE_PARNERTS_SUCCESS'
export const DELETE_PARNERTS_ERROR = 'DELETE_PARNERTS_ERROR'

export const EDIT_PARNERTS_REQUEST = 'EDIT_PARNERTS_REQUEST'
export const EDIT_PARNERTS_SUCCESS = 'EDIT_PARNERTS_SUCCESS'
export const EDIT_PARNERTS_ERROR = 'EDIT_PARNERTS_ERROR'

export const LOAD_ALL_PARNERT_REQUEST = 'LOAD_ALL_PARNERT_REQUEST';
export const LOAD_ALL_PARNERTS_SUCCESS = 'LOAD_ALL_PARNERTS_SUCCESS';
export const LOAD_ALL_PARNERT_ERROR = 'LOAD_ALL_PARNERT_ERROR';


export const loadPartners = payload => dispatch =>
  dispatch({
    type: LOAD_PARNERTS_REQUEST,
    meta: requestApi.get(`/api/v1/partner${payload.value}`)
      .then(response =>
        dispatch({
          type: LOAD_PARNERTS_SUCCESS,
          items: response.data,
          page: payload.page ? payload.page : 1
        })
      )
      .catch(e => dispatch({ type: LOAD_PARNERTS_ERROR, payload: e }))
  })

export const loadAllPartners = () => dispatch =>
  dispatch({
    type: LOAD_ALL_PARNERT_REQUEST,
    meta: requestApi.get(`/api/v1/partner?per_page=${100}`)
      .then(response =>
        dispatch({
          type: LOAD_ALL_PARNERTS_SUCCESS,
          items: response.data,
        })
      )
      .catch(e => dispatch({ type: LOAD_ALL_PARNERT_ERROR, payload: e }))
  })

export const changeStatus = payload => dispatch =>
  dispatch({
    type: CHANGE_PARTNER_STATUS,
    payload
  })

export const createPartner = payload => dispatch =>
  dispatch({
    type: CREATE_PARNERTS_REQUEST,
    meta: requestApi.post('/api/v1/partner', payload)
      .then(() =>
        dispatch({
          type: CREATE_PARNERTS_SUCCESS,
          payload: true
        })
      )
      .catch(e => dispatch({ type: CREATE_PARNERTS_ERROR, payload: e }))
  })

export const loadPartnerDetail = ({ id }) => dispatch =>
  dispatch({
    type: LOAD_PARNERT_REQUEST,
    meta: requestApi.get(`/api/v1/partner/${id}`)
      .then(response =>
        dispatch({
          type: LOAD_PARNERT_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: LOAD_PARNERT_ERROR, payload: e }))
  })

export const deletePartner = ({ id }) => dispatch =>
  dispatch({
    type: DELETE_PARNERTS_REQUEST,
    meta: requestApi.delete(`/api/v1/partner/${id}`)
      .then(response =>
        dispatch({
          type: DELETE_PARNERTS_SUCCESS,
          payload: response.data
        })
      )
      .catch(e => dispatch({ type: DELETE_PARNERTS_ERROR, payload: e }))
  })

export const editPartner = ({ id, values }) => dispatch =>
  dispatch({
    type: EDIT_PARNERTS_REQUEST,
    meta: requestApi.put(`/api/v1/partner/${id}`, values)
      .then(() =>
        dispatch({
          type: EDIT_PARNERTS_SUCCESS,
          payload: true
        })
      )
      .catch(e => dispatch({ type: EDIT_PARNERTS_ERROR, payload: e }))
  })
import { toast } from "react-toastify";
import {
  GET_USERS_START,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  CREATE_SINGLE_USER_STARTED,
  CREATE_SINGLE_USER_SUCCESS,
  CREATE_SINGLE_USER_FAILURE,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_TOTAL_USERS_BY_MONTHS_START,
  GET_TOTAL_USERS_BY_MONTHS_SUCCESS,
  GET_TOTAL_USERS_BY_MONTHS_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
} from "redux/actionTypes";

import requestApi from "utility/requestApi";

export const getUsers = (params) => async (dispatch) => {
  dispatch({ type: GET_USERS_START });
  try {
    const {
      data: { data: users, paging },
    } = await requestApi.get(`/api/v1/user${params}`);
    dispatch({ type: GET_USERS_SUCCESS, users, paging });
  } catch (err) {
    dispatch({
      type: GET_USERS_FAILURE,
      err,
    });
    toast.error("Something wrong happened, please try again!");
  }
};

export const forgotPass = (email) => async (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD });
  try {
    await requestApi.post(`/ChangePassword?email=${email}`);
    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    toast.success("Email Sent!");
  } catch (err) {
    dispatch({ type: FORGOT_PASSWORD_ERROR });
    toast.error("Email Doesn't exist!");
  }
};

export const getTotalUsersByMonth = (id) => async (dispatch) => {
  dispatch({ type: GET_TOTAL_USERS_BY_MONTHS_START });
  try {
    const {
      data: { data },
    } = await requestApi.get(`/api/v1/user/${id}/GetTotalByMonth`);
    dispatch({ type: GET_TOTAL_USERS_BY_MONTHS_SUCCESS, data });
  } catch (err) {
    dispatch({
      type: GET_TOTAL_USERS_BY_MONTHS_FAILURE,
      err,
    });
  }
};

export const createSingleUser =
  ({ data, action = null }) =>
  (dispatch) =>
    dispatch({
      type: CREATE_SINGLE_USER_STARTED,
      meta: requestApi
        .post(`/api/v1/user`, data)
        .then((response) => {
          !!action && action();
          dispatch({
            type: CREATE_SINGLE_USER_SUCCESS,
            user: response.data.data,
          });
        })
        .catch((err) => {
          dispatch({ type: CREATE_SINGLE_USER_FAILURE, payload: err });
          if (err.response?.data?.message) {
            if (/Invalid sign up/.test(err.response.data.message)) {
              toast.error("The user already exists and cannot be added");
            } else {
              toast.error(err.response.data.message);
            }
          } else {
            toast.error(err.message);
          }
        }),
    });

// export const createSingleUser = (data) => async (dispatch) => {
//   debugger;
//   dispatch({ type: CREATE_SINGLE_USER_STARTED, payload: data });
//   try {
//     const { data: user } = await requestApi.post(`/api/v1/user`, data);
//     dispatch({ type: CREATE_SINGLE_USER_SUCCESS, user });
//     toast.success("User created!");
//   } catch (err) {
//     dispatch({ type: CREATE_SINGLE_USER_FAILURE, err });
//     if (err.response?.data?.message) {
//       if (/Invalid sign up/.test(err.response.data.message)) {
//         toast.error("The user already exists and cannot be added");
//       } else {
//         toast.error(err.response.data.message);
//       }
//     } else {
//       toast.error(err.message);
//     }
//     return Promise.reject(err);
//   }
// };

export const updateUser =
  (data, options = {}) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_USER_START, payload: data });
    try {
      const { data: user } = await requestApi.put(
        `/api/v1/user/${data.id}`,
        data
      );
      dispatch({ type: UPDATE_USER_SUCCESS, user });
      toast.success("User updated!");
      if (options.reload) {
        window.location.reload();
      }
    } catch (err) {
      toast.error("there was an error with this action!");
      dispatch({ type: UPDATE_USER_FAILURE, err });
      return Promise.reject(err);
    }
  };

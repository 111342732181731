import {
  GET_ACCOUNTS_START,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  CREATE_ACCOUNT_START,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  GET_ACCOUNTS_BY_MONTH_SUCCESS,
  GET_ACCOUNTS_BY_MONTH_FAILURE,
  GET_ACCOUNTS_BY_MONTH_START,
  LOGOUT_SUCCESS,
} from "redux/actionTypes"

const initialState = {
  all: [],
  loading: true,
  err: null,
  loadingAccount: false,
  account: {},
  accountsByMonth: []
}

const accountsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_START:
    case GET_ACCOUNTS_BY_MONTH_START:
      return {...state, loading: true}
    case GET_ACCOUNTS_SUCCESS: 
      return {...state, loading: false, all: action.accounts.data, err: null}
    case GET_ACCOUNTS_FAILURE:
      return {...state, loading: false, err: action.err}
    case CREATE_ACCOUNT_START:
      return {...state, loadingAccount: true}
    case GET_ACCOUNTS_BY_MONTH_SUCCESS:
      return {...state, accountsByMonth: action.data}
    case CREATE_ACCOUNT_SUCCESS: 
      return {...state, loadingAccount: false, account: action.account, err: null}
    case CREATE_ACCOUNT_FAILURE:
      return {...state, loadingAccount: false, err: action.err}
      case UPDATE_ACCOUNT_START:
      return { ...state, loadingAccount: true }
    case UPDATE_ACCOUNT_SUCCESS:
      return { ...state, loadingAccount: false, account: action.account, err: null }
    case LOGOUT_SUCCESS: 
      return { ...initialState }
    case UPDATE_ACCOUNT_FAILURE:
    case GET_ACCOUNTS_BY_MONTH_FAILURE:
      return { ...state, loadingAccount: false, err: action.err }
    default:
      return state
  }
}

export default accountsReducers

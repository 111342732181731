import {
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CREATE_SINGLE_USER_STARTED,
  CREATE_SINGLE_USER_SUCCESS,
  CREATE_SINGLE_USER_FAILURE,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_TOTAL_USERS_BY_MONTHS_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  LOGOUT_SUCCESS,
} from "redux/actionTypes";

const initialState = {
  all: [],
  loading: true,
  err: null,
  loadingUser: false,
  loadingEmail: false,
  user: {},
  usersByMonth: [],
  paging: {},
  usersLoaded: false,
};

const usersReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_START:
      return { ...state, loading: true };
    case FORGOT_PASSWORD:
      return { ...state, loadingEmail: true };
    case GET_TOTAL_USERS_BY_MONTHS_SUCCESS:
      return { ...state, usersByMonth: action.data };
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_ERROR:
      return { ...state, loadingEmail: false };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        all: action.users,
        paging: action.paging,
        err: null,
        usersLoaded: true,
      };
    case GET_USERS_FAILURE:
      return { ...state, loading: false, err: action.err };
    case CREATE_SINGLE_USER_STARTED:
      return { ...state, loadingUser: true };
    case CREATE_SINGLE_USER_SUCCESS:
      return { ...state, loadingUser: false, user: action.user, err: null };
    case CREATE_SINGLE_USER_FAILURE:
      return { ...state, loadingUser: false, err: action.err };
    case UPDATE_USER_START:
      return { ...state, loadingUser: true };
    case UPDATE_USER_SUCCESS:
      return { ...state, loadingUser: false, user: action.user, err: null };
    case UPDATE_USER_FAILURE:
      return { ...state, loadingUser: false, err: action.err };
    case LOGOUT_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
};

export default usersReducers;

import { combineReducers } from 'redux';
import customizer from './customizer/';
import auth from './auth/';
import navbar from './navbar/Index';
import audits from './audits/index';
import users from './users';
import accounts from './accounts';
import partners from './partners';
import devices from './devices';
import settings from './settings';
import companies from './companies';
import buildings from './buildings';
import tags from './tags';
import permissions from './permissions';
import subscriptions from './subscriptions';
import qr from './qr';
import demo from './demo';

const rootReducer = combineReducers({
  audits,
  customizer,
  auth,
  navbar,
  users,
  accounts,
  devices,
  partners,
  settings,
  companies,
  buildings,
  tags,
  permissions,
  subscriptions,
  qr,
  demo,
})

export default rootReducer

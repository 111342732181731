import { LOGOUT_SUCCESS } from 'redux/actionTypes';
import {
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  GET_LOCATION_ERROR,
  GET_AREAS_SUCCESS,
  GET_CUSTOM_AREAS_SUCCESS,
  LOAD_TIME_N_TASK_SUCCESS,
  LOAD_GENERAL_SUCCESS,
  LOAD_FLOOR_SUCCESS,
  GET_AUDITS_BY_MONTH_REQUEST,
  GET_AUDITS_BY_MONTH_SUCCESS,
  GET_AVERAGE_AUDITS_SUCCESS,
  GET_HIGHEST_AUDITS_SUCCESS,
  GET_LOWEST_AUDITS_SUCCESS,
  LOAD_PRODUCTS_SUCCESS,
  GET_EXTERNAL_AREAS_REQUEST,
  GET_EXTERNAL_AREAS_SUCCESS,
  GET_EXTERNAL_AREAS_ERROR,
  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
} from '../../actions/audits';

const initialState = {
  items: [],
  page: 1,
  areas: [],
  item: {},
  customAreas: [],
  floor: [],
  general: [],
  timeNtask: [],
  products: [],
  auditsByMonth: [],
  highest: [],
  lowest: [],
  average: [],
  facilities: [],
  externalAreas: [],
  isLoading: false,
}

const auditsReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_LOCATIONS_REQUEST:
    case GET_LOCATION_REQUEST:
    case GET_FACILITIES_REQUEST:
    case GET_AUDITS_BY_MONTH_REQUEST:
      return {...state, isLoading: true}
    case GET_LOCATIONS_SUCCESS: 
      return {...state, isLoading: false, items: payload.items.data, page: payload.page ? payload.page : 1}
    case GET_AUDITS_BY_MONTH_SUCCESS: 
      return { ...state, auditsByMonth: payload.data } 
    case LOAD_PRODUCTS_SUCCESS:
      return { ...state, products: payload } 
    case GET_AVERAGE_AUDITS_SUCCESS:
      return{...state, average: payload.data}
    case GET_HIGHEST_AUDITS_SUCCESS:
      return{...state, highest: payload.data}
    case GET_LOWEST_AUDITS_SUCCESS:
      return{...state, lowest: payload.data}
    case GET_FACILITIES_SUCCESS:
      return{...state, facilities: payload}
    case GET_LOCATION_SUCCESS: 
      let myAssesments = {}
      let count = {
        Excelent: 0,
        Good: 0,
        Improvement: 0,
        Poor: 0,
        Hazzard: 0
      }
      payload.zones.map( ({areas}) =>
        areas.map(({id, customAreas}) => {
          if (customAreas.length > 0){
            const externalID = id
            if (!myAssesments[externalID]){
              myAssesments[externalID] = []
            }
            return customAreas.map( ({assesments, id, name, note, photos}) => {
              let myScore = 0
              assesments.map( ({score}) => {
                score > 0 && score <= 1 && count.Hazzard++
                score > 1 && score <= 2 && count.Poor++
                score > 2 && score <= 3 && count.Improvement++
                score > 3 && score <= 4 && count.Good++
                score > 4 && score <= 5 && count.Excelent++
                score > 0 && score <= 5 && (myScore += score)
                return score
              })
              return myAssesments[externalID].push({ items: assesments.sort( (a, b) => {
                var keyA = a.score,
                  keyB = b.score;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              }), id, name, note, photos, score: assesments.filter( ({score}) => (score <= 5 && score > 0)).length > 0 ? parseFloat(myScore / assesments.filter( ({score}) => (score <= 5 && score > 0) ).length).toFixed(2) : 0 })
            })
          }
        })
      )

      return {...state, isLoading: false, item: {...payload, assesments: myAssesments, count}}
    case GET_AREAS_SUCCESS:
      return {...state, areas: payload}
    case LOAD_FLOOR_SUCCESS:
      return {...state, areas: payload, floor: payload}
    case LOAD_GENERAL_SUCCESS:
      return {...state, areas: payload, general: payload}
    case LOGOUT_SUCCESS: 
      return { ...initialState }
    case LOAD_TIME_N_TASK_SUCCESS:
      return {...state, areas: payload, timeNtask: payload}
    case GET_CUSTOM_AREAS_SUCCESS:
      return {...state, customAreas: payload}
    case GET_LOCATIONS_ERROR:
    case GET_LOCATION_ERROR:
      return {...state, isLoading: false}

    case GET_EXTERNAL_AREAS_REQUEST:
      return { ...state, loadingExternalAreas: true }
    case GET_EXTERNAL_AREAS_SUCCESS:
      return { ...state, loadingExternalAreas: false, externalAreas: payload }
    case GET_EXTERNAL_AREAS_ERROR:
      return {...state, loadingExternalAreas: false}
    case DELETE_LOCATION_REQUEST:
      return {...state, isLoading: true}
    case DELETE_LOCATION_SUCCESS:
      return {...state, isLoading: false}
    case DELETE_LOCATION_ERROR:
      return {...state, isLoading: false}

    default:
      return state
  }
}

export default auditsReducer

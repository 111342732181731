import React, { useEffect } from "react"
import { Navbar, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
// import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import { useSelector, useDispatch } from "react-redux"
import * as Icon from "react-feather"
import { openReport } from "redux/actions/navbar/Index"
import { useParams } from "react-router-dom"
// import { getAuditReport } from "redux/actions/audits"
import { updateUser } from "redux/actions/users"
import cookies from 'utility/cookieHandler';
import Select from 'react-select';
import { loadAllPartners } from "redux/actions/partners"


const ThemeNavbar = props => {
  const user = useSelector(({ auth }) => auth.currentUser);
  // const {id} = useParams()
  const dispatch = useDispatch()
  const logged = useSelector(({ auth }) => auth.logged);
  const allPartners = useSelector(({ partners }) =>  partners.all,);
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating", "static", "sticky", "hidden"]

  useEffect(() => {
    if (logged) {
      dispatch(loadAllPartners())
    }
  }, [dispatch, logged])

  if (!logged) return null;

  // const downloadReport = () => {
  //   dispatch(getAuditReport({id}))
  // }

  const changePartner = (id) => {
    dispatch(updateUser({ id: user.id, partnerId: id }, {reload: true}));
    cookies.setCookie("partnerId", id)
  }

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div style={{width: '20rem'}}>
                {
                  user?.role === 'Super Admin' ? (
                    <Select
                      placeholder="Change Partner"
                      name="partnerId"
                      options={allPartners.map(item => ({ label: item.nameCompany, value: item.id }))}
                      onChange={(option) => {
                        if (option.value) {
                          changePartner(option.value);
                        }
                      }}
                    />
                  ) : null
                }

              </div>
              <div className="bookmark-wrapper">
                {window.location.pathname.includes('/audits/') && (
                  <NavItem
                    className="nav-item d-none d-lg-block"
                  >

                    <NavLink onClick={() => dispatch(openReport(true))} >
                      <Icon.Mail size={21} /> Email Report
                    </NavLink>
                    {/* <NavLink onClick={downloadReport} >
                      <Icon.File size={21} /> Download Report
                    </NavLink> */}
                  </NavItem>
                )}
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={`${user.name} ${user.lastName || ""}`}
                userImg={userImg}
                nameCompany={user.nameCompany}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}


export default ThemeNavbar

import {
  GET_GENERAL_SETTINGS_START,
  GET_GENERAL_SETTINGS_SUCCESS,
  GET_GENERAL_SETTINGS_FAILURE,
  SAVE_GENERAL_SETTINGS_START,
  SAVE_GENERAL_SETTINGS_SUCCESS,
  SAVE_GENERAL_SETTINGS_FAILURE,
  LOGOUT_SUCCESS,
} from 'redux/actionTypes';

const initialState = {
  error: null,
  loading: false,
  id: '',
  partnerId: '',
  companyName: '',
  primaryAdminName: '',
  primaryAdminTitle: '',
  primaryAdminEmail: '',
  primaryAdminPhone: '',
  secondaryAdminName: '',
  secondaryAdminTitle: '',
  secondaryAdminEmail: '',
  secondaryAdminPhone: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_SETTINGS_START: {
      return { ...state, loading: true }
    }
    case GET_GENERAL_SETTINGS_SUCCESS: {
      return { ...state, loading: false, ...action.payload }
    }
    case GET_GENERAL_SETTINGS_FAILURE: {
      return { ...state, loading: false, error: action.err }
    }
   
    case SAVE_GENERAL_SETTINGS_START: {
      return state
    }
    case SAVE_GENERAL_SETTINGS_SUCCESS: {
      return { ...state, ...action.payload }
    }
    case SAVE_GENERAL_SETTINGS_FAILURE: {
      return { ...state, error: action.err }
    }
    case LOGOUT_SUCCESS: 
      return { ...initialState }
    default: {
      return state
    }
  }
}

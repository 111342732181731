import { combineReducers } from "redux";
import generalReducer from './general';
import estimatesReducer from './estimates';
import regionReducer from './region';
import notificationReducer from './notification';
import reportsReducer from './reports';

const settingsReducers = combineReducers({
  estimates: estimatesReducer,
  general: generalReducer,
  region: regionReducer,
  notification: notificationReducer,
  report: reportsReducer,
})

export default settingsReducers;
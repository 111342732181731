import {
  GET_ESTIMATE_SETTINGS_START,
  GET_ESTIMATE_SETTINGS_SUCCESS,
  GET_ESTIMATE_SETTINGS_FAILURE,
  SAVE_ESTIMATE_SETTINGS_START,
  SAVE_ESTIMATE_SETTINGS_SUCCESS,
  SAVE_ESTIMATE_SETTINGS_FAILURE,
  LOGOUT_SUCCESS,
} from 'redux/actionTypes';

const initialState = {
  error: null,
  loading: false,
  id: '',
  partnerId: '',
  suggestedCorrectiveAction: true,
  allowCorrectiveAction: true,
  includeWorkloadingTime: true,
  allowWorkloadingTime: true,
  suggestedProductRecommendations: true,
  scoringSettings: 'passFail',
  changeScoringFormat: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ESTIMATE_SETTINGS_START: {
      return { ...state, loading: true }
    }
    case GET_ESTIMATE_SETTINGS_SUCCESS: {
      return { ...state, loading: false, ...action.payload }
    }
    case GET_ESTIMATE_SETTINGS_FAILURE: {
      return { ...state, loading: false, error: action.err }
    }

    case LOGOUT_SUCCESS: 
    return { ...initialState }

    case SAVE_ESTIMATE_SETTINGS_START: {
      return state
    }
    case SAVE_ESTIMATE_SETTINGS_SUCCESS: {
      return { ...state, ...action.payload }
    }
    case SAVE_ESTIMATE_SETTINGS_FAILURE: {
      return { ...state, error: action.err }
    }

    default: {
      return state
    }
  }
}

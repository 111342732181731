import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from 'components/@vuexy/breadCrumbs/BreadCrumb';
import { Row, Col } from 'reactstrap';
import DataListConfig from 'components/data-list/DataListConfig';
import queryString from 'query-string';
import SidebarUserForm from './components/sidebarUserForm';
import { getUsers, createSingleUser, updateUser } from 'redux/actions/users';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { chunk } from 'utility/chunk';
import { useHistory } from 'react-router-dom';
import sortTable from 'utility/sortTable';
import { db } from 'firebase.config';
import { getRoles } from 'redux/actions/roles';

const columns = [
  {
    name: 'NAME',
    selector: (user) => `${user.name} ${user.lastName || ''}`,
    sortable: true,
  },
  {
    name: 'EMAIL ADDRESS',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'ROLE',
    selector: 'role',
    sortable: true,
  },
  {
    name: 'APP ACCESS',
    selector: (user) => (user.appAccess ? 'Yes' : 'No'),
    sortable: true,
  },
];

const newUser = {
  email: '',
  name: '',
  lastName: '',
  status: 'active',
  phone: '',
  area: 'NorthWest',
  role: 0,
};

export const permitsEnum = {
  users: 1,
  billing: 2,
  audits: 3,
  app: 4,
  known: 1,
};

const Users = () => {
  const {
    page: currentPage,
    query: currentQuery,
    sort: sortName,
    sortDirection,
  } = queryString.parse(window.location.search);
  const [currentData, setCurrentData] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  const [page, setPage] = useState(currentPage || 1);
  const [totalPages, setTotalPages] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [chunkedData, setChunkedData] = useState([]);
  const [query, setQuery] = useState(currentQuery || '');
  const [sortColumn, setSortColumn] = useState();
  const history = useHistory();
  const loading = useSelector(({ users }) => users.loading);
  const users = useSelector(({ users }) => users.all);
  const partner = useSelector(({ auth }) => auth.currentUser.partnerId);
  const currentPermits = useSelector(
    ({ auth }) => auth.currentUser.permissions,
  );
  const roles = useSelector(({ permissions }) => permissions.roles);
  const userId = useSelector(({ auth }) => auth.currentUser.id);
  const dispatch = useDispatch();

  const formatTags = (tags) =>
    tags.map((tag) => ({
      ...tag,
      label: tag.tag || tag.name,
      value: tag.id,
      name: tag.tag || tag.name,
    }));

  const handleCurrentData = (user) => {
    const newData = {
      ...user,
      userTags: formatTags(user.userTags || []),
      buildingTags: formatTags(user.buildingTags || []),
      customerTags: formatTags(user.customerTags || []),
    };
    setCurrentData(newData);
    setSidebar(true);
  };

  const handleDelete = () => {
    console.log('handle');
  };

  const getData = (values) => {
    dispatch(getUsers(`?${queryString.stringify({ partner, per_page: 100 })}`));
  };

  const canEditUsers = () => {
    return Boolean(
      currentPermits.find(
        (permit) =>
          permit.name === 'Add/Edit Users' || permit.name === 'Super Admin',
      ),
    );
  };

  const handleSaveUser = () => {
    if (!canEditUsers()) {
      setSidebar(false);
      return toast.error('You don\'t have permission to add/edit users');
    }

    const userData = currentData;
    userData.role = roles.find((role) => role.name === userData.role).id;
    const roleName = roles.find((role) => role.id === userData.role).name;
    delete userData.permits;

    if (userData.userTags) {
      userData.userTags = userData.userTags.map((tag) => tag.value);
    } else {
      userData.userTags = [];
    }
    if (userData.customerTags) {
      userData.customerTags = userData.customerTags.map((tag) => tag.value);
    } else {
      userData.customerTags = [];
    }
    if (userData.buildingTags) {
      userData.buildingTags = userData.buildingTags.map((tag) => tag.value);
    } else {
      userData.buildingTags = [];
    }


    if (currentData.id) {
      dispatch(updateUser(userData));
    } else {
      const action = () => {
        setSidebar(false);
        getData(queryString.parse(window.location.search));
      };

      dispatch(
        createSingleUser({ data: { ...userData, partnerId: partner }, action }),
      );

      switch (roleName) {
        case 'Auditor':
          sentEmailApprove({ ...userData, roleName });
          break;
        case 'Compliance':
          sentEmailApprove({ ...userData, roleName });
          break;
        case 'Administrator':
          sentEmailApprove({ ...userData, roleName });
          break;
        case 'Site Owner':
          sentEmailApprove({ ...userData, roleName });
          break;
        default:
          break;
      }
    }
  };

  const emailGreetByRole = (roleName) => {
    switch (roleName) {
      case 'Auditor':
        return `You have been added to Faciliscan<sup style="font-size: 0.2em">TM</sup> as an <b>Auditor</b> for your company. To help you get started with the program, please follow the instructions below to complete the setup of your account`;
      case 'Compliance':
      case 'Compliance Manager':
        return `You have been added to Faciliscan<sup style="font-size: 0.2em">TM</sup> as an <b>Compliance Manager</b> for your company and may have capabilities to evaluate &amp; send audit reports to respective stakeholders and prepare building profiles. To help you get started with the program, please follow the instructions below to complete the setup of your account and download the Faciliscan<sup style="font-size: 0.2em">TM</sup> App:`;
      case 'Administrator':
        return `You have been added to Faciliscan<sup style="font-size: 0.2em">TM</sup> as an <b>Administrator</b> for your company. To help you get started with the program, please follow the instructions below to complete the setup of your account:`;
      case 'Site Owner':
        return `You have been added to Faciliscan<sup style="font-size: 0.2em">TM</sup> as the <b>Site Owner</b> role for your company and have access to all capabilities &amp; features in the admin site and app. To help you get started with the program, please follow the instructions below to complete the setup for your organization:`;
      default:
        return `You have been added to Faciliscan<sup style="font-size: 0.2em">TM</sup> as an <b>${roleName}</b> for your company. To help you get started with the program, please follow the instructions below to complete the setup of your account:`;
    }
  }

  const sentEmailApprove = (data) => {
    db.collection('mail').add({
      to: data.email,
      bcc: ['scjpfaciliscan@scj.com', 'support@kitschier.com'],
      message: {
        subject: 'Welcome! You’ve been invited to join faciliscan',
        html: `
        <html>
          <body topmargin="0" leftmargin="0">
            <table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;line-height:24px;margin:0;padding:0;width:100%;font-size:17px; color:#373737; background: #f9f9f9;">
              <tbody>
                <tr>
                  <td valign="top" style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse">
                      <tbody>
                        <tr>
                          <td valign="bottom" style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse;padding:20px 16px 12px">
                            <div style="text-align:center; margin: 15px">
                              <a href="https://www.faciliscan.com" target="_blank">
                              <img src="https://admin.faciliscan.com/static/media/login.acf776e8.png" width="240" style="outline:none;text-decoration:none;border:none" class="CToWUd"></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top" style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse">
                    <table cellpadding="10" cellspacing="0" border="0" align="center" style="border-collapse:collapse;background:white;border-radius:0.5rem;margin-bottom:1rem">
                      <tbody>
                        <tr>
                          <td width="588" valign="top" style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse">
                            <div style="max-width:588px;margin:0 auto; margin-top: 20px; padding:20px">
                              <h2 style="color:#d9282f;line-height:32px;margin:0 auto 0.75rem;font-size:1.8rem;text-align:center;">
                                Welcome to the<br>faciliscan<sup style="font-size:0.2em">TM</sup> mobile audit program!
                              </h2>
                              <p style="line-height: 1.5em">
                                ${emailGreetByRole(data?.role)}
                              </p>
                              <ol style="line-height: 1.5em">
                                <li><b>Activate Your faciliscan<sup style="font-size:0.2em">TM</sup> Account</b><br>Your account has already been created. Check your email for a separate message with instructions for setting your password and access your account.</li>
                                <li><b>Setup faciliscan<sup style="font-size:0.2em">TM</sup> for your company.</b><br>Refer to the <a href="https://faciliscan.com/tutorial/Faciliscan_Admin_Overview.pdf" style="color:#d9282f" target="_blank">User Guide</a> for establishing your company's Audit settings, along with adding Users, Customers and Buildings.</li>
                              </ol>
                              <p style="line-height: 1.5em">If you have any questions, please visit the Help Desk within the faciliscan website to send a message.</p>
                              <p>Thanks!<br>The faciliscan<sup style="font-size:0.2em">TM</sup> Team</p>
                              <br />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table cellpadding="32" cellspacing="0" border="0" align="center" style="border-collapse:collapse;background:white;border-radius:0.5rem;margin-bottom:1rem">
                      <tbody>
                        <tr>
                          <td width="546" valign="top" style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse">
                            <div style="max-width:600px;margin:0 auto">
                              <table cellpadding="10" cellspacing="0" border="0" align="center" style="border-collapse:collapse;background:white;border-radius:0.5rem;margin-bottom:1rem">
                                <tbody>
                                  <tr>
                                    <td>
                                      <img src="https://faciliscan.com/tutorial/Faciliscan-Home-Screen.png">
                                    </td>
                                    <td>
                                      <h2 style="line-height:32px;margin:0 auto 0.75rem;font-size:1.8rem;text-align:center;">
                                        Download the <span style="color:#d9282f">faciliscan<sup style="font-size:0.2em">TM</sup></span><br>Mobile App
                                      </h2>
                                      <center>
                                        <p>The faciliscan<sup style="font-size:0.2em">TM</sup>iOS and Android app allows users to perform building audits from their mobile device.</p>
                                        <div style="margin-top: 40px">
                                          <a href="https://apps.apple.com/us/app/faciliscan/id1540009180"><img src="https://faciliscan.com/images/app-store.png" class="img-fluid m-2 reveal fadeInUp" data-wow-delay="0.6s" style="max-width: 150px; visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;" alt="Download faciliscan from iTunes"></a>
                                          <a href="https://play.google.com/store/apps/details?id=com.scjpauditplatform"><img src="https://faciliscan.com/images/play-store.png" class="img-fluid m-2 reveal fadeInUp" data-wow-delay="0.6s" style="max-width: 150px; visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;" alt="Download faciliscan from Google Play"></a>
                                        </div>
                                      </center>
                                    </td>
                                  </tr>
                                  <tr>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table cellpadding="32" cellspacing="0" border="0" align="center" style="border-collapse:collapse;background:white;border-radius:0.5rem;margin-bottom:1rem">
                      <tbody>
                        <tr>
                          <td width="546" valign="top" style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse">
                            <div style="max-width:600px;margin:0 auto">
                              <center>
                                <p style="font-size:17px;line-height:24px;margin:0 0 16px">
                                  If you need assistance or have questions, please reach out to <a href="mailto:scjpFaciliscan@scj.com" style="color:#d9282f">scjpFaciliscan@scj.com</a>
                                </p>
                              </center>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0" align="center" style="border-collapse:collapse;margin-top:1rem; background:#1f1f1f; color:#ffffff">
                      <tbody>
                        <tr>
                          <td valign="top" align="center" style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif!important;border-collapse:collapse;padding:16px 8px 24px">
                            <div style="max-width:600px;margin:0 auto">
                              <img src="https://faciliscan.com/images/logo-white-sm.png" style="max-width: 200px;">
                              <p style="font-size:12px;line-height:20px;margin:0 0 16px;margin-top:16px">
                                2815 Coliseum Centre Drive, Suite 600, Charlotte, NC 28217 United States
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </body>
        </html>
        `,
      },
    });
  };

  const handleSetPage = (number) => {
    setPage(number);
    const params = queryString.parse(window.location.search);
    params.page = number;
    history.push(`?${queryString.stringify(params)}`);
  };

  const handleFilter = (text) => {
    handleSetPage(1);
    setQuery(text);
    const params = queryString.parse(window.location.search);
    params.query = text;
    history.push(`?${queryString.stringify(params)}`);
  };

  const handleSort = (column, sortDirection) => {
    if (column.sortable) {
      setSortColumn({ ...column, sortDirection });
      const params = queryString.parse(window.location.search);
      params.sort = column.name;
      params.sortDirection = sortDirection;
      history.push(`?${queryString.stringify(params)}`);
    }
  };

  useEffect(() => {
    let newData = users;

    if (query) {
      newData = newData.filter((user) =>
        RegExp(query.toLowerCase()).test(user.name.toLowerCase()),
      );
    }

    if (sortColumn) {
      newData = sortTable(newData, sortColumn);
    }

    setChunkedData(chunk(newData, pageSize));
  }, [users, pageSize, query, sortColumn]);
  useEffect(() => {
    setTotalPages(chunkedData.length);
  }, [chunkedData]);
  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);
  useEffect(() => {
    setQuery(currentQuery);
  }, [currentQuery]);
  useEffect(() => {
    let column = columns.find((item) => item.name === sortName);
    if (column) {
      column = { ...column, sortDirection };
    }
    setSortColumn(column);
  }, [sortName, sortDirection]);

  useEffect(() => {
    dispatch(getRoles({ userId }));
  }, [dispatch, userId]);

  return (
    <div>
      <BreadCrumbs breadCrumbTitle="Users" />
      <Row>
        <Col sm="12">
          <DataListConfig
            onRowClicked={handleCurrentData}
            form={
              <SidebarUserForm
                values={currentData || {}}
                setValues={setCurrentData}
              />
            }
            columns={columns}
            data={chunkedData[page - 1] || chunkedData[0]}
            currentData={currentData}
            sidebar={sidebar}
            setSidebar={setSidebar}
            handleDelete={handleDelete}
            getData={getData}
            currentPage={'/users'}
            parsedFilter={queryString.parse(window.location.search)}
            sidebarSubmitText="Save"
            newButton={canEditUsers()}
            newButtonOnClick={() => handleCurrentData({ ...newUser })}
            sidebarTitle="USER DETAILS"
            handleSubmit={handleSaveUser}
            loading={loading}
            page={page}
            setPage={handleSetPage}
            totalPages={totalPages}
            setRowsPerPage={setPageSize}
            rowsPerPage={pageSize}
            handleFilter={handleFilter}
            query={query}
            selectable={false}
            onSort={handleSort}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Users;
